
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'sign-up-screen',
      title: 'Cadastro de clínica',
      campos: [{
        type: 'password',
        label: 'Senha',
        name: 'senha',
        required: true
      }, {
        type: 'password',
        label: 'Confirmar senha',
        name: 'confirmar_senha',
        required: true
      }]
    };
  },

  mounted() {
    this.$root.maskInputs();
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      console.log('onSubmit');
      const self = this,
            formEl = self.$el.find('form');
      let formData = app.form.convertToData(formEl);
      if (formData.senha != formData.confirmar_senha) app.dialog.alert(null, 'As senhas não coincidem');else self.$root.request('post', '/cadastrar/clinica', formData, res => {
        app.dialog.alert('A administração do OHV irá realizar a análise e aprovação do cadastro no sistema. Assim que for realizado, você receberá um e-mail.', 'Cadastro realizado com sucesso!', () => {
          self.$router.navigate('/login');
        });
      }, 'preloader');
    }

  },
  id: 'c89ed8396b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-navbar no-toolbar no-swipeback login-screen-page" data-name="';
      r += c(ctx_1.name, ctx_1);
      r += '">\r\n    \r\n    <div class="page-content">\r\n      <div class="login-screen-content">\r\n        <div class="login-screen-title mb-30">';
      r += c(ctx_1.title, ctx_1);
      r += '</div>\r\n        <form class="list min-w-75" @submit="onSubmit">\r\n          <div class="row">\r\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <div class="col">\r\n                <div class="list inline-labels no-hairlines m-0">\r\n                  <ul>\r\n                    ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                      <li class="item-content item-input">\r\n                        <div class="item-inner">\r\n                          <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="text-color-red">*</span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\r\n                          <div class="item-input-wrap">\r\n                            <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" autocomplete="on" ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'required';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' />\r\n                            <span class="input-clear-button"></span>\r\n                          </div>\r\n                        </div>\r\n                      </li>\r\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                  </ul>\r\n                </div>\r\n              </div>\r\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          </div>\r\n          <div class="row">\r\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.campos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <div class="col">\r\n                <div class="list inline-labels no-hairlines m-0">\r\n                  <ul>\r\n                    <li class="item-content item-input">\r\n                      <div class="item-inner">\r\n                        <div class="item-title item-label">';
          r += c(ctx_2.label, ctx_2);
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.required, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<span class="text-color-red">*</span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                        <div class="item-input-wrap">\r\n                          <input type="';
          r += c(ctx_2.type, ctx_2);
          r += '" name="';
          r += c(ctx_2.name, ctx_2);
          r += '" autocomplete="on" ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.required, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'required';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' />\r\n                          <span class="input-clear-button"></span>\r\n                        </div>\r\n                      </div>\r\n                    </li>\r\n                  </ul>\r\n                </div>\r\n              </div>\r\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          </div>\r\n          <div class="block-footer mt-50">\r\n            <button type="submit" class="button button-fill button-large mt-20 mb-20">Cadastrar</button>\r\n            <a href="/login" class="button button-large">Já tenho conta</a>\r\n            <a href="/sign-up" class="button button-large">Voltar</a>\r\n          </div>\r\n        </form>\r\n      </div>\r\n    </div>\r\n    \r\n  </div>\r\n';
      return r;
    }(this);
  },

  style: `
  .page-content {
    background-color: #fff;
  }
  .panel-left {
    display: none !important;
  }
  .view-main {
    margin-left: 0px !important;
  }
`,
  styleScoped: false
};
    