export default {
  data() {
    return {
      name: 'login',
      title: 'Login'
    };
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      const self = this,
            formEl = this.$el.find('form');
      const {
        email,
        senha
      } = app.form.convertToData(formEl);
      self.$root.request('get', '/login', null, res => {
        self.$root.openSession(res);
        self.$root.getNotificationsFromAPI();
      }, 'preloader', null, null, null, {
        headers: {
          'Authorization': 'Bearer ' + String.encodeBase64(email + ':' + senha)
        }
      });
    }

  },
  id: '262cee21dd',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-toolbar no-swipeback login-screen-page" data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=login-screen-content><div class="login-screen-title mt-0 mb-0"><div class=mb-5>';
      r += c(ctx_1.title, ctx_1);
      r += '</div><div class="fs-20 st">Acesso ao cadastro, requisição e resultados</div></div><form @submit=onSubmit><div class=list><ul><li class="item-content item-input"><div class=item-media><i class="icon mdi mdi-email-outline color-primary"></i></div><div class=item-inner><div class="item-title item-floating-label">E-mail</div><div class=item-input-wrap><input type=email name=email autocomplete=on required> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-media><i class="icon mdi mdi-lock-outline color-primary"></i></div><div class=item-inner><div class="item-title item-floating-label">Senha</div><div class=item-input-wrap><input type=password name=senha autocomplete=on required> <span class=input-clear-button></span></div></div></li></ul><div class="block-footer mt-30"><button type=submit class="button button-fill button-large mt-20 mb-20">Entrar</button><p class="mb-5 strong"><a href=/esqueci-minha-senha>Esqueci minha senha</a></p><p class="mb-5 strong">Não tem cadastro?<br><a href=/sign-up>Cadastre-se!</a></p></div></div></form></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .page-content {
    background-color: #fff;
  }
  .panel-left {
    display: none !important;
  }
  .view-main {
    margin-left: 0px !important;
  }
`,
  styleScoped: false
};