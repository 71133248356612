
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'notificacoes',
      title: 'Notificações',
      notificacoes: []
    };
  },

  mounted() {
    const usuario_id = app.rootComponent.user_account._id;
    const self = this,
          {
      tipo_cadastro
    } = self.$root.user_account;
    self.$root.request('get', '/notificacoes', {
      usuario_id,
      tipo_cadastro
    }, res => {
      let {
        notificacoes
      } = res;

      if (tipo_cadastro == 3) {
        notificacoes.map(notificacao => {
          let {
            tipo_notificacao
          } = notificacao;

          if (tipo_notificacao) {
            switch (tipo_notificacao) {
              case 1:
                tipo_notificacao = 'clinicas';
                break;

              case 2:
                tipo_notificacao = 'veterinarios';
                break;

              case 3:
                tipo_notificacao = 'exames';
                break;
            }

            notificacao.link = '/administracao/aprovacoes/' + tipo_notificacao;
          }
        });
      }

      self.$setState({
        notificacoes
      }, () => {
        self.$root.noResultCheck(notificacoes);
      });
    }, 'preloader');
  },

  methods: {
    goPage(link) {
      if (link) this.$router.navigate(link);
    }

  },
  id: '39a1bf14fe',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class="card-content card-content-padding"><div class="no-result d-none"><div class="block center p-25">Sem notificações!</div></div><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.notificacoes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div @click="goPage(\'';
          r += c(ctx_2.link, ctx_2);
          r += '\')" class="item-content ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.user_account.tipo_cadastro == 3', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'item-link';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><div class=item-media><i class="icon mdi mdi-bell-ring"></i></div><div class=item-inner><div class=item-title-row><div class=item-title>';
          r += c(ctx_2.titulo, ctx_2);
          r += '</div><div class=item-after>';
          r += Template7Helpers.js.call(ctx_2, "moment(this.data_inclusao).format('DD/MM/YYYY HH:mm')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class=item-subtitle>';
          r += c(ctx_2.subtitulo, ctx_2);
          r += '</div><div class=item-text>Observações: ';
          r += Template7Helpers.js.call(ctx_2, "this.observacoes || 'sem observações'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '.</div></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    