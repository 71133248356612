
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'cadastrar-animal',
      title: 'Cadastrar animal',
      loading: false
    };
  },

  mounted() {
    const self = this,
          {
      search_value
    } = self.$route.params;
    self.$root.request('get', `/buscar/tutor/${search_value}`, null, res => {
      self.$tick(() => {
        let formEl = self.$el.find('form');
        app.form.fillFromData(formEl, res.tutor);
        self.$root.maskInputs();
      });
    }, 'loading');
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const {
        search_value
      } = this.$route.params;
      const self = this,
            formEl = self.$el.find('form');
      const formData = app.form.convertToData(formEl);
      self.$root.request('post', '/cadastrar/animal', formData, res => {
        self.$router.navigate(`/animais/tutor/${search_value}`);
      }, 'preloader');
    }

  },
  id: 'c6fd672afe',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page" data-name="';
      r += c(ctx_1.name, ctx_1);
      r += '">\r\n    \r\n    <div class="page-content">\r\n\r\n      <div class="breadcrumb">\r\n        <div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '\r\n          <a href="/animais/tutor/';
      r += c(ctx_1.$route.params.search_value, ctx_1);
      r += '" class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a>\r\n        </div>\r\n        <div class="block">\r\n          <a href="/animais/buscar">Buscar animais</a> <span>/</span>\r\n          <a href="/animais/tutor/';
      r += c(ctx_1.$route.params.search_value, ctx_1);
      r += '">Animais do tutor</a> <span>/</span>\r\n          <a href="#">Cadastrar</a>\r\n        </div>\r\n      </div>\r\n\r\n      <div class="card">\r\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="progressbar-infinite"></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        <div class="card-content card-content-padding">\r\n          <form @submit="handleSubmit">\r\n            <div class="row">\r\n              ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.animal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <div class="col">\r\n                  <div class="list inline-labels no-hairlines">\r\n                    <ul>\r\n                      ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <li class="item-content item-input">\r\n                          <div class="item-inner">\r\n                            <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="text-color-red">*</span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\r\n                            <div class="item-input-wrap ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.name == \'idade\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'input-dropdown-wrap';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '">\r\n                              <div class="row">\r\n                                <div class="col">\r\n                                  <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'required';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' />\r\n                                  ';
              r += Template7Helpers.js_if.call(ctx_3, "this.name != 'idade'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="input-clear-button"></span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n                                </div>\r\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, "this.name == 'idade'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                                  <div class="col">\r\n                                    <select name="tipo_idade">\r\n                                      <option value="1">anos</option>\r\n                                      <option value="2">meses</option>\r\n                                    </select>\r\n                                  </div>\r\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n                              </div>\r\n                            </div>\r\n                          </div>\r\n                        </li>\r\n                      ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                    </ul>\r\n                  </div>\r\n                </div>\r\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n            <div class="block mt-30 mb-15">\r\n              <button type="submit" class="button button-fill button-large">Cadastrar</button>\r\n            </div>\r\n          </form>\r\n        </div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    