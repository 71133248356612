import '@mdi/font/css/materialdesignicons.min.css';
import jQuery from 'jquery';
import 'jquery-mask-plugin';
import moment from 'moment';
import sortJsonArray from 'sort-json-array';

import socketAPI from 'socket.io-client';

export default (f7) => {
  window.app = f7;
  window.$ = app.$;
  window.jQuery = jQuery;
  window.moment = moment;
  moment.locale('pt-BR');
  window.socketAPI = socketAPI(app.rootComponent.api.url);
  Object.isEmpty = (obj) => (Object.keys(obj).length == 0 ? true : false);
  Array.sortObjects = (arr, key, order) => sortJsonArray(arr, key, order);
  String.getOnlyNumbers = (string) => String.filter(string, /\D/g);
  Object.clone = (obj = {}) => JSON.parse(JSON.stringify(obj));
  String.filter = (string, regex) => string.replace(regex, '');
  Object.toString = (obj = {}) => JSON.stringify(obj);
  Array.isEmpty = (arr) => (arr.length === 0);
  String.toObject = (obj) => {
    try { return JSON.parse(obj); } catch(err) { return {}; }
  };
  String.encodeBase64 = (string) => btoa(string);
  String.decodeBase64 = (string) => atob(string);
  String.isBoolean = (string) => (/(^true$)|(^false$)/.test(string) ? true : false);
  String.toBoolean = (string) => (String.isBoolean(string) ? JSON.parse(string) : null);
  jQuery.expr[":"].contains = jQuery.expr.createPseudo((arg) => {
    return (elem) => jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
  });
  jQuery.fn.extend({
    maskPhone() {
      let SPMaskBehavior = function(val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
      },
      spOptions = {
        onKeyPress: function(val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
      };
      this.each((index, el) => {
        jQuery(el).mask(SPMaskBehavior, spOptions);
      });
    }
  });

  // ======================= NOTIFICATIONS ======================= 
  const notifications = [];
  window.socketAPI.on('notifications', (items) => {
    const { session_token, user_account } = app.rootComponent;
    if (!session_token || Object.isEmpty(user_account)) return;
    const { tipo_cadastro, _id } = user_account;
    const item = (items.length > 0 ? items[0] : {});
    const usuario_id = (tipo_cadastro == 3 ? 'administracao' : _id);
    const isClinicaNotification = (item.clinica == usuario_id);
    const isVeterinarioNotification = (item.veterinario == usuario_id);
    const isAdministracaoNotification = (item.administracao == true && tipo_cadastro == 3);
    if (isClinicaNotification || isVeterinarioNotification || isAdministracaoNotification) {
      const notificacoes_ids = items.map(item => { notifications.push(item); return item._id; });
      window.socketAPI.emit('notificationsReceived', { usuario_id, notificacoes_ids });
    }
  });
  setInterval(() => {
    const notification = notifications.shift();
    if (notification) app.rootComponent.showNotification(notification);
  }, app.params.notification.closeTimeout + 1000);
  // ======================= NOTIFICATIONS =======================

};

