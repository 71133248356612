
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'exame-aprovacao-visualizar',
      title: 'Visualizar exame',
      exame: {
        veterinario: {},
        veterinario: {},
        clinica: {},
        animal: {},
        exames: []
      }
    };
  },

  mounted() {
    const self = this,
          {
      exame_id
    } = self.$route.params;
    self.$root.request('get', `/buscar/exame/${exame_id}`, {
      select: '-resultados'
    }, res => {
      let {
        exame
      } = res;
      self.$root.maskInputs();

      if (!exame.clinica) {
        exame.clinica = {};
        exame.sem_clinica = true;
      }

      if (exame) self.$setState({
        exame
      });else app.dialog.alert(null, 'Exame inativo ou inválido!', () => {
        self.$router.navigate('/exames');
      });
    }, 'preloader');
  },

  methods: {
    mostrarDadosDaClinica() {
      const popupEl = this.$el.find('.popup-clinica'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.clinica);
      this.$root.maskInputs();
      app.popup.open(popupEl);
    },

    mostrarDadosDoVeterinario() {
      const popupEl = this.$el.find('.popup-veterinario'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.veterinario);
      this.$root.maskInputs();
      app.popup.open(popupEl);
    },

    mostrarDadosDoAnimal() {
      const popupEl = this.$el.find('.popup-animal'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.animal);
      this.$root.maskInputs();
      app.popup.open(popupEl);
    }

  },
  id: '934ec7ed1d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page" data-name="';
      r += c(ctx_1.name, ctx_1);
      r += '">\r\n    \r\n    <div class="page-content">\r\n\r\n      <div class="breadcrumb">\r\n        <div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '\r\n          <a href="/administracao/aprovacoes/exames" class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a>\r\n        </div>\r\n        <div class="block">\r\n          <a href="#">Administracao</a> <span>/</span>\r\n          <a href="#">Aprovações</a> <span>/</span>\r\n          <a href="/administracao/aprovacoes/exames">Exames</a> <span>/</span>\r\n          <a href="#">Visualizar</a>\r\n        </div>\r\n      </div>\r\n\r\n      <div class="card">\r\n        <div class="card-content card-content-padding">\r\n\r\n          <form>\r\n\r\n            <div class="row no-gap">\r\n  \r\n              <div class="col-50">\r\n                <div class="list inline-labels no-hairlines no-hairlines-between">\r\n                  <ul>\r\n                    ';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <li>\r\n                        <label class="item-checkbox item-content no-click">\r\n                          <input type="checkbox" name="sem_clinica" ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.exame.sem_clinica, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' />\r\n                          <i class="icon icon-checkbox"></i>\r\n                          <div class="item-inner">\r\n                            <div class="item-title">Exame sem vínculo com uma clínica</div>\r\n                          </div>\r\n                        </label>\r\n                      </li>\r\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                    <li class="item-content item-input clinica">\r\n                      <div class="item-inner">\r\n                        <div class="item-title item-label w-100px mt-5 f-bold">Clínica</div>\r\n                        <div class="item-input-wrap ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.exame.clinica._id', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\r\n                          <input type="hidden" name="clinica" value="';
      r += c(ctx_1.exame.clinica._id, ctx_1);
      r += '" />\r\n                          <div class="row">\r\n                            <div class="item-content" style="width: calc(100% - 50px);">\r\n                              <div class="item-inner">\r\n                                <div class="item-title">';
      r += Template7Helpers.js.call(ctx_1, "this.exame.clinica.nome_fantasia || 'Sem clínica'", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n                                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.exame.clinica.cnpj, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="item-text">CNPJ: ';
          r += c(ctx_2.exame.clinica.cnpj, ctx_2);
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                              </div>\r\n                            </div>\r\n                            <a class="button tooltip-init" @click="mostrarDadosDaClinica" href="#" data-tooltip="Visualizar"><i class="icon mdi mdi-eye"></i></a>\r\n                          </div>\r\n                        </div>\r\n                      </div>\r\n                    </li>\r\n                    <li class="item-content item-input">\r\n                      <div class="item-inner">\r\n                        <div class="item-title item-label w-100px mt-5 f-bold">Animal</div>\r\n                        <div class="item-input-wrap">\r\n                          <input type="hidden" name="animal" value="';
      r += c(ctx_1.exame.animal._id, ctx_1);
      r += '" />\r\n                          <div class="row">\r\n                            <div class="item-content" style="width: calc(100% - 50px);">\r\n                              <div class="item-inner">\r\n                                <div class="item-title">';
      r += c(ctx_1.exame.animal.nome_paciente, ctx_1);
      r += '</div>\r\n                                <div class="item-text">CPF do tutor: ';
      r += c(ctx_1.exame.animal.cpf_tutor, ctx_1);
      r += '</div>\r\n                              </div>\r\n                            </div>\r\n                            <a class="button tooltip-init" @click="mostrarDadosDoAnimal" href="#" data-tooltip="Visualizar"><i class="icon mdi mdi-eye"></i></a>\r\n                          </div>\r\n                        </div>\r\n                      </div>\r\n                    </li>\r\n                    ';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <li>\r\n                        <label class="item-checkbox item-content no-click">\r\n                          <input type="checkbox" name="urgente" ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.exame.urgente, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' />\r\n                          <i class="icon icon-checkbox color-red"></i>\r\n                          <div class="item-inner text-color-red">\r\n                            <div class="item-title">URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div>\r\n                          </div>\r\n                        </label>\r\n                      </li>\r\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  </ul>\r\n                </div>\r\n              </div>\r\n  \r\n              <div class="col-50">\r\n                <div class="list inline-labels no-hairlines no-hairlines-between">\r\n                  <ul>\r\n                    ';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <li>\r\n                        <label class="item-checkbox item-content no-click">\r\n                          <input type="checkbox" name="urgente" ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.exame.urgente, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' />\r\n                          <i class="icon icon-checkbox color-red"></i>\r\n                          <div class="item-inner text-color-red">\r\n                            <div class="item-title">URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div>\r\n                          </div>\r\n                        </label>\r\n                      </li>\r\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                    <li class="item-content item-input veterinario">\r\n                      <div class="item-inner">\r\n                        <div class="item-title item-label w-100px mt-5 f-bold">Veterinário</div>\r\n                        <div class="item-input-wrap">\r\n                          <input type="hidden" name="veterinario" value="';
      r += c(ctx_1.exame.veterinario._id, ctx_1);
      r += '" />\r\n                          <div class="row">\r\n                            <div class="item-content" style="width: calc(100% - 50px);">\r\n                              <div class="item-inner">\r\n                                <div class="item-title">';
      r += c(ctx_1.exame.veterinario.nome, ctx_1);
      r += '</div>\r\n                                <div class="item-text">CPF: ';
      r += c(ctx_1.exame.veterinario.cpf, ctx_1);
      r += '</div>\r\n                              </div>\r\n                            </div>\r\n                            <a class="button tooltip-init" @click="mostrarDadosDoVeterinario" href="#" data-tooltip="Visualizar"><i class="icon mdi mdi-eye"></i></a>\r\n                          </div>\r\n                        </div>\r\n                      </div>\r\n                    </li>\r\n                    <li class="item-content item-input">\r\n                      <div class="item-inner">\r\n                        <div class="item-title item-label w-150px mt-5 f-bold">Suspeita clínica</div>\r\n                        <div class="item-input-wrap">\r\n                          <input type="text" name="suspeita_clinica" disabled value="';
      r += c(ctx_1.exame.suspeita_clinica, ctx_1);
      r += '" />\r\n                        </div>\r\n                      </div>\r\n                    </li>\r\n                  </ul>\r\n                </div>\r\n              </div>\r\n  \r\n            </div>\r\n            \r\n            <div class="block-title f-bold fs-16 mt-25 mb-30">Exames</div>\r\n            <div class="block">\r\n              ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.exame.exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <div class="chip chip-outline color-primary mr-10 mb-10">\r\n                  <div class="chip-label fs-16">';
          r += c(ctx_2.nome, ctx_2);
          r += '</div>\r\n                </div>\r\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n\r\n          </form>\r\n\r\n        </div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n    <div class="popup popup-clinica" style="--f7-popup-tablet-width: 1024px;">\r\n      <div class="view">\r\n        <div class="page">\r\n          <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n              <div class="title">Dados da clínica</div>\r\n              <div class="right">\r\n                <a class="link popup-close">\r\n                  <i class="icon mdi mdi-close"></i>\r\n                </a>\r\n              </div>\r\n            </div>\r\n          </div>\r\n          <div class="page-content">\r\n            <form class="block mt-0">\r\n              <div class="row">\r\n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <div class="col">\r\n                    <div class="list inline-labels no-hairlines">\r\n                      <ul class="no-bg">\r\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                          <li class="item-content item-input">\r\n                            <div class="item-inner">\r\n                              <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div>\r\n                              <div class="item-input-wrap">\r\n                                <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" readonly />\r\n                              </div>\r\n                            </div>\r\n                          </li>\r\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      </ul>\r\n                    </div>\r\n                  </div>\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n              </div>\r\n            </form>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="popup popup-veterinario" style="--f7-popup-tablet-width: 1024px;">\r\n      <div class="view">\r\n        <div class="page">\r\n          <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n              <div class="title">Dados do veterinário</div>\r\n              <div class="right">\r\n                <a class="link popup-close">\r\n                  <i class="icon mdi mdi-close"></i>\r\n                </a>\r\n              </div>\r\n            </div>\r\n          </div>\r\n          <div class="page-content">\r\n            <form class="block mt-0">\r\n              <div class="row">\r\n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.veterinario, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <div class="col">\r\n                    <div class="list inline-labels no-hairlines">\r\n                      <ul class="no-bg">\r\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                          <li class="item-content item-input">\r\n                            <div class="item-inner">\r\n                              <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div>\r\n                              <div class="item-input-wrap">\r\n                                <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" readonly />\r\n                              </div>\r\n                            </div>\r\n                          </li>\r\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      </ul>\r\n                    </div>\r\n                  </div>\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n              </div>\r\n            </form>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="popup popup-animal" style="--f7-popup-tablet-width: 1024px;">\r\n      <div class="view">\r\n        <div class="page">\r\n          <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n              <div class="title">Dados do animal</div>\r\n              <div class="right">\r\n                <a class="link popup-close">\r\n                  <i class="icon mdi mdi-close"></i>\r\n                </a>\r\n              </div>\r\n            </div>\r\n          </div>\r\n          <div class="page-content">\r\n            <form class="block mt-0">\r\n              <div class="row">\r\n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.animal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <div class="col">\r\n                    <div class="list inline-labels no-hairlines">\r\n                      <ul class="no-bg">\r\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                          <li class="item-content item-input">\r\n                            <div class="item-inner">\r\n                              <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div>\r\n                              <div class="item-input-wrap">\r\n                                <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" readonly />\r\n                              </div>\r\n                            </div>\r\n                          </li>\r\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      </ul>\r\n                    </div>\r\n                  </div>\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n              </div>\r\n            </form>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },

  style: `
  [data-tooltip="Visualizar"] {
    border-radius: 0px;
    line-height: 45px;
    height: auto;
    width: 50px;
  }
[data-f7-934ec7ed1d] .border-top {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
`,
  styleScoped: true
};
    