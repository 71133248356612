
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'panel-left',
      title: 'Menu'
    };
  },

  methods: {
    handleLogout() {
      const self = this;
      app.dialog.create({
        title: 'Deseja mesmo sair da conta?',
        buttons: [{
          text: 'Sim, sair',
          onClick: () => self.$root.closeSession()
        }, {
          text: 'Cancelar'
        }]
      }).open();
    }

  } // { path: '#', title: 'Administração', icon: 'shield-account', sublinks: [
  //   { path: '#', title: 'Aprovações', icon: 'file-document-box-check-outline', sublinks: [
  //     { path: '/solicitacoes-de-cadastro/clinicas', title: 'Clínicas', permissions: [3] },
  //     { path: '/solicitacoes-de-cadastro/veterinarios', title: 'Veterinários', permissions: [3] },
  //     { path: '/solicitacoes-de-cadastro/exames', title: 'Exames', permissions: [3] },
  //   ]},
  //   { path: '/formulario-de-exames', title: 'Formulário de exames', icon: 'clipboard-text-outline', permissions: [3] },
  // ]},
  ,
  id: '532856e516',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class="navbar h-150px"><div class="navbar-inner sliding center"><a href=/ class=title data-view=.view-main><img height=100 width=auto src=static/logo.png></a></div></div><div class="block-title mt-0">Navegação</div><div class="list accordion-list"><ul><li><a href=/minha-conta class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-account"></i></div><div class=item-inner><div class=item-title>Minha conta</div></div></a></li><li><a href=/exames class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-sticker-check-outline"></i></div><div class=item-inner><div class=item-title>Exames</div></div></a></li>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/clinicas class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-hospital-building"></i></div><div class=item-inner><div class=item-title>Clínicas</div></div></a></li><li><a href=/veterinarios class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-doctor"></i></div><div class=item-inner><div class=item-title>Veterinários</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a href=/animais/buscar class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-paw"></i></div><div class=item-inner><div class=item-title>Animais</div></div></a></li>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class="accordion-item sub1"><a href=# class="item-link item-content"><div class=item-media><i class="icon mdi mdi-shield-account"></i></div><div class=item-inner><div class=item-title>Administração</div></div></a><div class=accordion-item-content><div class=list><ul><li class="accordion-item sub2"><a href=# class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-text-box-check-outline"></i></div><div class=item-inner><div class=item-title>Aprovações</div></div></a><div class=accordion-item-content><div class=list><ul><li><a href=/administracao/aprovacoes/clinicas class="item-link item-content" data-view=.view-main><div class=item-inner><div class=item-title>Clínicas</div></div></a></li><li><a href=/administracao/aprovacoes/veterinarios class="item-link item-content" data-view=.view-main><div class=item-inner><div class=item-title>Veterinários</div></div></a></li><li><a href=/administracao/aprovacoes/exames class="item-link item-content" data-view=.view-main><div class=item-inner><div class=item-title>Exames</div></div></a></li></ul></div></div></li><li><a href=/administracao/formulario-de-exames class="item-link item-content tooltip-init" data-view=.view-main data-tooltip="Formulário de exames"><div class=item-media><i class="icon mdi mdi-clipboard-text-outline"></i></div><div class=item-inner><div class=item-title>Formulário de exames</div></div></a></li><li><a href=/administracao/modelos-de-exames class="item-link item-content tooltip-init" data-view=.view-main data-tooltip="Modelos de exames"><div class=item-media><i class="icon mdi mdi-note-text-outline"></i></div><div class=item-inner><div class=item-title>Modelos de exames</div></div></a></li></ul></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a href=/notificacoes class="item-link item-content" data-view=.view-main><div class=item-media><i class="icon mdi mdi-bell"></i></div><div class=item-inner><div class=item-title>Notificações</div></div></a></li><li><a href=# class="item-link item-content" @click=handleLogout><div class=item-media><i class="icon mdi mdi-logout-variant"></i></div><div class=item-inner><div class=item-title>Sair da conta</div></div></a></li></ul></div></div></div>';
      return r;
    }(this);
  },

  style: `
[data-f7-532856e516] .sub1 .accordion-item-content ul {
    background-color: #eee;
  }
[data-f7-532856e516] .sub1 .accordion-item-content .item-link {
    padding-left: 40px !important;
  }
[data-f7-532856e516] .sub2 .accordion-item-content ul {
    background-color: #ddd;
  }
[data-f7-532856e516] .sub2 .accordion-item-content .item-link {
    padding-left: 100px !important;
  }
[data-f7-532856e516] .item-inner {
    margin-left: 0px !important;
  }
`,
  styleScoped: true
};
    