
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import data from './js/data';
import methods from './js/methods';
export default {
  data,
  methods,
  id: '5dc68f3f40',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><div class="panel panel-left panel-cover panel-init" data-visible-breakpoint=960>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.session_token, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="view view-init" data-name=left data-url=/component/panel-left></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="view view-main view-init safe-areas" data-url=/ data-push-state=true data-push-state-separator="">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.session_token, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon mdi mdi-menu"></i></a></div><div class="title sliding center"><i class="icon mdi mdi-account mr-15"></i>';
          r += c(ctx_2.user_account.nome, ctx_2);
          r += '</div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><!-- Popup --><!-- <div class="popup" id="my-popup">\r\n      <div class="view">\r\n        <div class="page">\r\n          <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n              <div class="title">Popup</div>\r\n              <div class="right">\r\n                <a href="#" class="link popup-close">Close</a>\r\n              </div>\r\n            </div>\r\n          </div>\r\n          <div class="page-content">\r\n            <div class="block">\r\n              <p>Popup content goes here.</p>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div> --></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    