export default {
  data() {
    return {
      name: 'animais-buscar',
      title: 'Buscar animais'
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const self = this,
            search_value = this.$el.find('input').val();
      self.$router.navigate(`/animais/tutor/${search_value}`);
    }

  },
  id: 'abaae851a6',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class="card-content card-content-padding"><form @submit=handleSubmit class="list inline-labels p-20"><ul><li class="item-content item-input"><div class=item-inner><div class="item-input-wrap mr-20"><input type=text placeholder="Insira o CPF do tutor, nome do tutor ou nome do animal" required> <span class=input-clear-button></span></div><button type=submit class="button button-fill"><i class="icon mdi mdi-magnify mr-5"></i> Buscar animais</button></div></li></ul></form></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};