
import pExames from '../pages/exames.f7.html';
import pCadastrarExame from '../pages/cadastrar-exame.f7.html';
import pVisualizarExame from '../pages/visualizar-exame.f7.html';
import pEditarExame from '../pages/editar-exame.f7.html';
import pExamesAprovacoes from '../pages/exames-aprovacoes.f7.html';
import pExamesAprovacoesVisualizar from '../pages/exames-aprovacoes-visualizar.f7.html';

import pClinicas from '../pages/clinicas.f7.html';
import pCadastrarClinica from '../pages/cadastrar-clinica.f7.html';
import pVisualizarClinica from '../pages/visualizar-clinica.f7.html';
import pEditarClinica from '../pages/editar-clinica.f7.html';
import pClinicasAprovacoes from '../pages/clinicas-aprovacoes.f7.html';
import pClinicasAprovacoesVisualizar from '../pages/clinicas-aprovacoes-visualizar.f7.html';
import pVisualizarVeterinarioVinculadoEditar from '../pages/visualizar-veterinario-vinculado-editar.f7.html';
import pVisualizarVeterinarioVinculadoVisualizar from '../pages/visualizar-veterinario-vinculado-visualizar.f7.html';
import pVisualizarVeterinarioVinculadoMinhaConta from '../pages/visualizar-veterinario-vinculado-minha-conta.f7.html';

import pVeterinarios from '../pages/veterinarios.f7.html';
import pCadastrarVeterinario from '../pages/cadastrar-veterinario.f7.html';
import pVisualizarVeterinario from '../pages/visualizar-veterinario.f7.html';
import pEditarVeterinario from '../pages/editar-veterinario.f7.html';
import pVeterinariosAprovacoes from '../pages/veterinarios-aprovacoes.f7.html';
import pVeterinariosAprovacoesVisualizar from '../pages/veterinarios-aprovacoes-visualizar.f7.html';

import pAnimaisBuscar from '../pages/animais-buscar.f7.html';
import pAnimaisTutor from '../pages/animais-tutor.f7.html';
import pCadastrarAnimal from '../pages/cadastrar-animal.f7.html';
import pVisualizarAnimal from '../pages/visualizar-animal.f7.html';
import pEditarAnimal from '../pages/editar-animal.f7.html';

import pFormularioDeExames from '../pages/formulario-de-exames.f7.html';
import pModelosDeExames from '../pages/modelos-de-exames.f7.html';

import pNotificacoes from '../pages/notificacoes.f7.html';
import pMinhaConta from '../pages/minha-conta.f7.html';
import pLogin from '../pages/login.f7.html';
import pSignUp from '../pages/sign-up.f7.html';
import pSignUpClinica from '../pages/sign-up-clinica.f7.html';
import pSignUpVeterinario from '../pages/sign-up-veterinario.f7.html';
import pEsqueciMinhaSenha from '../pages/esqueci-minha-senha.f7.html';
import p404 from '../pages/404.f7.html';

import cPanelLeft from '../components/panel-left.f7.html';

function checkAuth(routeTo, routeFrom, resolve, reject) {
  const { rootComponent } = this.app, pathException = (/(^\/login$)|(^\/sign-up)/.test(routeTo.path));
  if (rootComponent.session_token) {
    if (pathException) this.navigate('/'); else resolve();
  } else {
    if (!pathException) this.navigate('/login'); else resolve();
  }
  reject();
}

let routes = [
  { path: '/', redirect: '/exames' },
  { path: '/exames', component: pExames, beforeEnter: [checkAuth] },
  { path: '/exames/cadastrar', component: pCadastrarExame, beforeEnter: [checkAuth] },
  { path: '/exames/visualizar/:exame_id', component: pVisualizarExame, beforeEnter: [checkAuth] },
  { path: '/exames/editar/:exame_id', component: pEditarExame, beforeEnter: [checkAuth] },
  { path: '/clinicas', component: pClinicas, beforeEnter: [checkAuth] },
  { path: '/clinicas/cadastrar', component: pCadastrarClinica, beforeEnter: [checkAuth] },
  { path: '/clinicas/visualizar/:clinica_id', component: pVisualizarClinica, beforeEnter: [checkAuth] },
  { path: '/clinicas/editar/:clinica_id', component: pEditarClinica, beforeEnter: [checkAuth] },
  { path: '/veterinarios', component: pVeterinarios, beforeEnter: [checkAuth] },
  { path: '/veterinarios/cadastrar', component: pCadastrarVeterinario, beforeEnter: [checkAuth] },
  { path: '/veterinarios/visualizar/:veterinario_id', component: pVisualizarVeterinario, beforeEnter: [checkAuth] },
  { path: '/veterinarios/editar/:veterinario_id', component: pEditarVeterinario, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/clinicas', component: pClinicasAprovacoes, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/clinicas/visualizar/:clinica_id', component: pClinicasAprovacoesVisualizar, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/veterinarios', component: pVeterinariosAprovacoes, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/veterinarios/visualizar/:veterinario_id', component: pVeterinariosAprovacoesVisualizar, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/exames', component: pExamesAprovacoes, beforeEnter: [checkAuth] },
  { path: '/administracao/aprovacoes/exames/visualizar/:exame_id', component: pExamesAprovacoesVisualizar, beforeEnter: [checkAuth] },
  { path: '/administracao/formulario-de-exames', component: pFormularioDeExames, beforeEnter: [checkAuth] },
  { path: '/administracao/modelos-de-exames', component: pModelosDeExames, beforeEnter: [checkAuth] },
  { path: '/animais/buscar', component: pAnimaisBuscar, beforeEnter: [checkAuth] },
  { path: '/animais/tutor/:search_value', component: pAnimaisTutor, beforeEnter: [checkAuth] },
  { path: '/animais/tutor/:search_value/cadastrar', component: pCadastrarAnimal, beforeEnter: [checkAuth] },
  { path: '/animais/tutor/:search_value/visualizar/:animal_id', component: pVisualizarAnimal, beforeEnter: [checkAuth] },
  { path: '/animais/tutor/:search_value/editar/:animal_id', component: pEditarAnimal, beforeEnter: [checkAuth] },
  { path: '/minha-conta/visualizar-veterinario-vinculado/:veterinario_id', component: pVisualizarVeterinarioVinculadoMinhaConta, beforeEnter: [checkAuth] },
  { path: '/clinicas/editar/:clinica_id/visualizar-veterinario-vinculado/:veterinario_id', component: pVisualizarVeterinarioVinculadoEditar, beforeEnter: [checkAuth] },
  { path: '/clinicas/visualizar/:clinica_id/visualizar-veterinario-vinculado/:veterinario_id', component: pVisualizarVeterinarioVinculadoVisualizar, beforeEnter: [checkAuth] },
  { path: '/notificacoes', component: pNotificacoes, beforeEnter: [checkAuth] },
  { path: '/minha-conta', component: pMinhaConta, beforeEnter: [checkAuth] },
  { path: '/login', component: pLogin, beforeEnter: [checkAuth] },
  { path: '/sign-up', component: pSignUp, beforeEnter: [checkAuth] },
  { path: '/sign-up/clinica', component: pSignUpClinica, beforeEnter: [checkAuth] },
  { path: '/sign-up/veterinario', component: pSignUpVeterinario, beforeEnter: [checkAuth] },
  { path: '/esqueci-minha-senha', component: pEsqueciMinhaSenha },
  { path: '/component/panel-left', component: cPanelLeft },
  { path: '(.*)', component: p404, beforeEnter: [checkAuth] },
];

export default routes;