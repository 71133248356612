
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'exames-aprovacoes',
      title: 'Aprovações de exames',
      loading: false,
      exames: []
    };
  },

  mounted() {
    this.listarRegistros();
  },

  updated() {
    this.$root.maskInputs();
  },

  methods: {
    listarRegistros(options = {}) {
      const self = this;
      options.data_aprovacao = {
        $exists: false
      };
      self.$root.request('get', '/listar/exames', options, res => {
        let {
          exames
        } = res;
        exames.map(exame => {
          exame.nome_fantasia_clinica = (exame.clinica || {}).nome_fantasia;
          exame.nome_paciente_animal = exame.animal.nome_paciente;
          exame.nome_veterinario = exame.veterinario.nome;
          exame.data_solicitacao = exame.data_inclusao;
          exame.statusColor = '';
          if (exame.data_aprovacao) exame.statusColor = 'yellow'; // Aguardando resultado

          if (exame.data_aprovacao && exame.pendente) statusColor = 'lightblue'; // Aprovado com pendência

          if (exame.resultados.length) exame.statusColor = 'green'; // Resultado disponível

          if (!exame.data_aprovacao) exame.statusColor = 'purple'; // Pendente de aprovação
        });
        self.$setState({
          exames
        }, () => {
          self.$root.noResultCheck(exames);
        });
      }, 'loading');
    }

  },
  id: '343749cdef',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div><div class=block><a href=#>Administração</a> <span>/</span> <a href=#>Aprovações</a> <span>/</span> <a href=#>Exames</a></div></div><div class=card><div class=card-header><form class="searchbar searchbar-custom"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar class=w-300px @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button p-absolute left-0">Cancel</span></div></form></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=exames><thead><tr><th @click=$root.ordenar data-key-name=num_os class="label-cell sortable-cell sortable-asc sortable-cell-active">Nº da OS</th><th @click=$root.ordenar data-key-name=nome_fantasia_clinica class="label-cell sortable-cell sortable-asc">Clínica</th><th @click=$root.ordenar data-key-name=nome_veterinario class="label-cell sortable-cell sortable-asc">Veterinário</th><th @click=$root.ordenar data-key-name=nome_paciente_animal class="label-cell sortable-cell sortable-asc">Animal</th><th @click=$root.ordenar data-key-name=urgente class="label-cell sortable-cell sortable-asc">Urgência</th><th @click=$root.ordenar data-key-name=data_solicitacao class="label-cell sortable-cell sortable-asc">Data da solicitação</th><th class="numeric-cell pr-82">Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.num_os, ctx_2);
          r += '</td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "this.nome_fantasia_clinica || '--'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=label-cell>';
          r += c(ctx_2.nome_veterinario, ctx_2);
          r += '</td><td class=label-cell>';
          r += c(ctx_2.nome_paciente_animal, ctx_2);
          r += '</td><td class=label-cell><span class="badge status color-';
          r += Template7Helpers.if.call(ctx_2, ctx_2.urgente, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'red';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'gray st';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' min-w-auto min-h-auto p-5"></span></td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "moment(this.data_solicitacao).format('DD/MM/YYYY HH:mm')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=numeric-cell><div class="segmented float-right w-100px -mr-5"><a href=/administracao/aprovacoes/exames/visualizar/';
          r += c(ctx_2._id, ctx_2);
          r += ' data-tooltip=Visualizar class="button button-small tooltip-init center color-gray"><i class="icon mdi mdi-eye"></i></a> <a href=# @click=$root.opcoesAprovarCadastro data-tooltip="Aprovar cadastro" class="button button-small tooltip-init center color-primary"><i class="icon mdi mdi-plus-circle fs-22"></i></a></div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    