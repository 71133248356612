
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'visualizar-exame',
      title: 'Visualizar exame',
      exame: {
        veterinario: {},
        veterinario: {},
        clinica: {},
        animal: {},
        exames: []
      }
    };
  },

  mounted() {
    const self = this,
          {
      exame_id
    } = self.$route.params;
    self.$root.request('get', `/buscar/exame/${exame_id}`, {
      select: '-resultados'
    }, ({
      exame
    }) => {
      if (!exame.clinica) exame.clinica = {}, exame.sem_clinica = 'true';
      if (exame) exame.urgente = String(exame.urgente);
      if (exame) self.$setState({
        exame
      }, () => {
        const formEl = self.$el.find('form');
        self.$tick(() => app.form.fillFromData(formEl, exame));
      });else app.dialog.alert(null, 'Exame inativo ou inválido!', () => {
        self.$router.navigate('/exames');
      });
    }, 'preloader');
  },

  updated() {
    this.$root.maskInputs();
  },

  methods: {
    mostrarDadosDaClinica() {
      const popupEl = this.$el.find('.popup-clinica'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.clinica);
      app.popup.open(popupEl);
    },

    mostrarDadosDoVeterinario() {
      const popupEl = this.$el.find('.popup-veterinario'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.veterinario);
      app.popup.open(popupEl);
    },

    mostrarDadosDoAnimal() {
      const popupEl = this.$el.find('.popup-animal'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.exame.animal);
      app.popup.open(popupEl);
    }

  },
  id: '05694ebeb0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += ' <a href=/exames class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a></div><div class=block><a href=/exames>Exames</a> <span>/</span> <a href=#>Visualizar</a></div></div><div class=card><div class="card-content card-content-padding"><form><div class="row no-gap"><div class=col-50><div class="list inline-labels no-hairlines no-hairlines-between"><ul>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content no-click"><input type=checkbox name=sem_clinica value=true> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Exame sem vínculo com uma clínica</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li class="item-content item-input clinica"><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Clínica</div><div class="item-input-wrap ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.exame.clinica._id', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"><input type=hidden name=clinica value=';
      r += c(ctx_1.exame.clinica._id, ctx_1);
      r += '><div class=row><div class=item-content style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += Template7Helpers.js.call(ctx_1, "this.exame.clinica.nome_fantasia || 'Sem clínica'", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.exame.clinica.cnpj, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=item-text>CNPJ: <span class=cnpj>';
          r += c(ctx_2.exame.clinica.cnpj, ctx_2);
          r += '</span></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><a class="button tooltip-init" @click=mostrarDadosDaClinica href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Animal</div><div class=item-input-wrap><input type=hidden name=animal value=';
      r += c(ctx_1.exame.animal._id, ctx_1);
      r += '><div class=row><div class=item-content style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += c(ctx_1.exame.animal.nome_paciente, ctx_1);
      r += '</div><div class=item-text>CPF do tutor: <span class=cpf>';
      r += c(ctx_1.exame.animal.cpf_tutor, ctx_1);
      r += '</span></div></div></div><a class="button tooltip-init" @click=mostrarDadosDoAnimal href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content no-click"><input type=checkbox name=urgente value=true> <i class="icon icon-checkbox color-red"></i><div class="item-inner text-color-red"><div class=item-title>URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class=col-50><div class="list inline-labels no-hairlines no-hairlines-between"><ul>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content no-click"><input type=checkbox name=urgente value=true> <i class="icon icon-checkbox color-red"></i><div class="item-inner text-color-red"><div class=item-title>URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li class="item-content item-input veterinario"><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Veterinário</div><div class=item-input-wrap><input type=hidden name=veterinario value=';
      r += c(ctx_1.exame.veterinario._id, ctx_1);
      r += '><div class=row><div class=item-content style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += c(ctx_1.exame.veterinario.nome, ctx_1);
      r += '</div><div class=item-text>CPF: <span class=cpf>';
      r += c(ctx_1.exame.veterinario.cpf, ctx_1);
      r += '</span></div></div></div><a class="button tooltip-init" @click=mostrarDadosDoVeterinario href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label w-150px mt-5 f-bold">Suspeita clínica</div><div class=item-input-wrap><input type=text name=suspeita_clinica disabled=disabled value=';
      r += c(ctx_1.exame.suspeita_clinica, ctx_1);
      r += '></div></div></li></ul></div></div></div><div class="block-title f-bold fs-16 mt-25 mb-30">Exames</div><div class=block>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.exame.exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="chip chip-outline color-primary mr-10 mb-10"><div class="chip-label fs-16">';
          r += c(ctx_2.nome, ctx_2);
          r += '</div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div><div class="popup popup-clinica" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados da clínica</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div><div class="popup popup-veterinario" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados do veterinário</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.veterinario, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div><div class="popup popup-animal" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados do animal</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.animal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  [data-tooltip="Visualizar"] {
    border-radius: 0px;
    line-height: 45px;
    height: auto;
    width: 50px;
  }
[data-f7-05694ebeb0] .border-top {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
`,
  styleScoped: true
};
    