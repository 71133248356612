
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'clinica-aprovacao-visualizar',
      title: 'Visualizar clínica',
      loading: false
    };
  },

  mounted() {
    const self = this,
          {
      clinica_id
    } = self.$route.params;
    self.$root.request('get', `/buscar/clinica/${clinica_id}`, null, res => {
      self.$tick(() => {
        let formEl = self.$el.find('form');
        app.form.fillFromData(formEl, res.clinica);
        self.$root.maskInputs();
      });
    }, 'loading');
  },

  id: '405ece83ae',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page" data-name="';
      r += c(ctx_1.name, ctx_1);
      r += '">\r\n    \r\n    <div class="page-content">\r\n\r\n      <div class="breadcrumb">\r\n        <div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '\r\n          <a href="/administracao/aprovacoes/clinicas" class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a>\r\n        </div>\r\n        <div class="block">\r\n          <a href="#">Administracao</a> <span>/</span>\r\n          <a href="#">Aprovações</a> <span>/</span>\r\n          <a href="/administracao/aprovacoes/clinicas">Clínicas</a> <span>/</span>\r\n          <a href="#">Visualizar</a>\r\n        </div>\r\n      </div>\r\n\r\n      <div class="card">\r\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="progressbar-infinite"></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        <div class="card-content card-content-padding">\r\n          <form class="mb-15">\r\n            <div class="row">\r\n              ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <div class="col">\r\n                  <div class="list inline-labels no-hairlines">\r\n                    <ul>\r\n                      ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <li class="item-content item-input">\r\n                          <div class="item-inner">\r\n                            <div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<span class="text-color-red">*</span>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\r\n                            <div class="item-input-wrap">\r\n                              <input type="';
              r += c(ctx_3.type, ctx_3);
              r += '" name="';
              r += c(ctx_3.name, ctx_3);
              r += '" ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.required, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'required';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' />\r\n                            </div>\r\n                          </div>\r\n                        </li>\r\n                      ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                    </ul>\r\n                  </div>\r\n                </div>\r\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n          </form>\r\n        </div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    