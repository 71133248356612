
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'formulario-de-exames',
      title: 'Formulário de exames',
      loading: false,
      formulario_de_exames: []
    };
  },

  mounted() {
    const self = this;
    self.$root.request('get', '/listar/formulario-de-exames', null, res => {
      let {
        formulario_de_exames
      } = res;
      self.$setState({
        formulario_de_exames
      });
    }, 'loading');
  },

  methods: {
    adicionarTopico() {
      let _id = 'temp_' + app.utils.now();

      this.formulario_de_exames.push({
        _id,
        topico: '',
        exames: [{
          _id: '',
          nome: ''
        }]
      });
      this.$update(() => {
        jQuery('.treeview-toggle:last').click();
        jQuery('[name="topico_nome"]:last').focus();
      });
    },

    adicionarExame(e) {
      let el = jQuery(e.target).closest('.topico-content');
      let id = el.data('id');
      this.formulario_de_exames.map(i => {
        if (i._id == id) i.exames.push({
          _id: '',
          nome: ''
        });
      });
      this.$update(() => el.find('[name="exame_nome"]:last').focus());
    },

    removerTopico(e) {
      let buttonEl = $(e.target).closest('.button');
      app.tooltip.get(buttonEl).destroy();
      buttonEl.closest('.topico-content').remove();
    },

    removerExame(e) {
      let buttonEl = $(e.target).closest('.button');
      app.tooltip.get(buttonEl).destroy();
      buttonEl.closest('.exame-content').remove();
    },

    salvarAlteracoes(e) {
      e.preventDefault();
      const self = this,
            formEl = self.$el.find('form');
      let formData = [];
      formEl.find('.topico-content').forEach(topicoEl => {
        let _id = $(topicoEl).find('[name="topico_id"]').val();

        let topico = $(topicoEl).find('[name="topico_nome"]').val();
        let exames = [];
        $(topicoEl).find('.exame-content').forEach(exameEl => {
          const exame = {
            _id: $(exameEl).find('[name="exame_id"]').val(),
            nome: $(exameEl).find('[name="exame_nome"]').val()
          };
          if (!exame._id) delete exame['_id'];
          exames.push(exame);
        }); // let exames = jQuery(topicoEl).find('[name="exames"]').map((i, input) => input.value).get();

        if (topico && exames.length > 0) formData.push({
          _id,
          topico,
          exames
        });
      });
      formData.map(i => i._id.includes('temp_') ? delete i['_id'] : null);
      self.$root.request('put', '/salvar/formulario-de-exames', formData, res => null, 'preloader');
    }

  },
  id: 'b0919c62c1',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div><div class=block><a href=#>Administração</a> <span>/</span> <a href=#>Formulário de exames</a></div></div><div class=card>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content card-content-padding"><form @submit=salvarAlteracoes><div class=treeview><div class=treeview-item><div class=treeview-item-root><div class=treeview-item-content><div class="treeview-item-label f-bold pl-25">Tópicos</div></div></div></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.formulario_de_exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="treeview-item topico-content" data-id=';
          r += c(ctx_2._id, ctx_2);
          r += '><div class=treeview-item-root><div class="treeview-toggle tooltip-init" data-tooltip=Expandir></div><div class="treeview-item-content w-100"><div class="list m-0 w-100"><ul><li class="item-content item-input item-input-outline item-input-with-value"><div class=item-inner><div class=item-input-wrap><input type=text name=topico_id value="';
          r += Template7Helpers.js.call(ctx_2, 'String(this._id)', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" class=d-none> <input type=text name=topico_nome required value="';
          r += Template7Helpers.js.call(ctx_2, 'String(this.topico)', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></div></div></li></ul></div><div class="actions -ml-2"><a href=# @click=removerTopico class="button tooltip-init color-red center" data-tooltip=Remover><i class="icon mdi mdi-delete"></i></a></div></div></div><div class=treeview-item-children><div class=treeview-item><div href=# class=treeview-item-root><div class=treeview-item-content><div class="treeview-item-label f-bold" style="padding-left: 165px;">Exames</div></div></div></div>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.exames, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="treeview-item exame-content"><div class=treeview-item-root><div class="treeview-item-content w-100" style="padding-left: 150px;"><div class="list m-0 w-100"><ul><li class="item-content item-input item-input-outline item-input-with-value"><div class=item-inner><div class=item-input-wrap><input type=text name=exame_id value="';
              r += Template7Helpers.js.call(ctx_3, 'String(this._id)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '" class=d-none> <input type=text name=exame_nome required value="';
              r += Template7Helpers.js.call(ctx_3, 'String(this.nome)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"></div></div></li></ul></div><div class="actions -ml-2"><a href=# @click=removerExame class="button tooltip-init color-red center" data-tooltip=Remover><i class="icon mdi mdi-delete"></i></a></div></div></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=treeview-item><div class=treeview-item-root><div class="treeview-item-content w-100" style="padding-left: 150px;"><div class="block w-100 mt-7 mb-7 mr-38"><a href=# @click=adicionarExame class="button button-outline center"><i class="icon mdi mdi-plus mr-10"></i> Novo exame</a></div></div></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=treeview-item><div class=treeview-item-root><div class="treeview-item-content w-100 pl-8"><div class="block w-100 mt-7 mb-7 mr-38"><a href=# @click=adicionarTopico class="button button-outline center"><i class="icon mdi mdi-plus mr-10"></i> Novo tópico</a></div></div></div></div></div><div class="block mt-30 mb-15"><button type=submit class="button button-fill button-large">Salvar alterações</button></div></form></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
[data-f7-b0919c62c1] .treeview-toggle {
    margin-top: 5px;
    padding: 5px;
  }
[data-f7-b0919c62c1] .treeview-toggle:after {
    border-top-width: 7.5px;
    border-bottom-width: 7.5px;
    border-left-width: 10px;
  }
`,
  styleScoped: true
};
    