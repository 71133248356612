export default {
  data() {
    return {
      name: 'sign-up',
      title: 'Escolha seu tipo de conta'
    };
  },

  id: '308ee6c185',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-toolbar no-swipeback login-screen-page" data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=login-screen-content><div class="login-screen-title mt-0 mb-0">';
      r += c(ctx_1.title, ctx_1);
      r += '</div><div class="block-footer mt-50"><p class=mb-20><a href=/sign-up/clinica class="button button-fill button-large">Sou uma clínica</a></p><p class=mb-20><a href=/sign-up/veterinario class="button button-fill button-large">Sou um veterinário</a></p><p class=""><a href=/login class="button button-large">Voltar</a></p></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .page-content {
    background-color: #fff;
  }
  .panel-left {
    display: none !important;
  }
  .view-main {
    margin-left: 0px !important;
  }
`,
  styleScoped: false
};