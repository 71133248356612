import Framework7 from 'framework7/framework7.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';
import '../scss/app.scss';
import '../scss/utils.scss';
import '../scss/theme.scss';
import routes from './routes';
import App from '../app.f7.html';
import config from './config';

new Framework7({
  root: '#app',
  component: App,
  name: 'OHV Pet',
  theme: 'md',
  routes,
  notification: {
    closeTimeout: 5000
  },
  popup: {
    on: {
      open: () => app.rootComponent.maskInputs(),
    }
  },
  on: {
    init() {
      config(this);
      app.rootComponent.loadSession();
      if (app.rootComponent.session_token)
      app.rootComponent.getNotificationsFromAPI();
    },
    pageInit: () => app.view.main.router.clearPreviousHistory()
  }
});

