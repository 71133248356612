
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'cadastrar-exame',
      title: 'Cadastrar exame',
      searching_animal: false,
      formulario_de_exames: [],
      veterinarios: [],
      clinicas: [],
      animais: [],
      clinica: {},
      veterinario: {},
      animal: {}
    };
  },

  mounted() {
    const self = this,
          {
      tipo_cadastro,
      usuario_id
    } = self.$root.user_account;
    if (tipo_cadastro != 3) self.$root.request('get', `/buscar/usuario/${usuario_id}`, null, res => {
      let param = tipo_cadastro == 1 ? 'clinica' : 'veterinario';
      self.$el.find('.item-input.' + param).addClass('disabled');
      self[param] = res.dados_cadastro;
      self.$update();
    }, 'loading');
    self.$root.request('get', '/listar/formulario-de-exames', null, ({
      formulario_de_exames
    }) => {
      formulario_de_exames.map(topico => {
        let exames = topico.exames;
        topico.exames = [];
        let metade = exames.length / 2 + 1;
        let a1 = exames.slice(0, metade);
        let a2 = exames.slice(metade);
        if (a1.length > 0) topico.exames.push(a1);
        if (a2.length > 0) topico.exames.push(a2);
      });
      self.$setState({
        formulario_de_exames
      });
    }, 'preloader');
  },

  updated() {
    this.$root.maskInputs();
  },

  methods: {
    mostrarClinicas() {
      const self = this,
            popupEl = self.$el.find('.popup-clinicas'),
            veterinario_id = self.veterinario._id;

      if (veterinario_id) {
        self.$root.request('get', '/listar/vinculos', {
          veterinario_id,
          select: ['vinculados']
        }, res => {
          let clinicas = res.vinculos.map(i => i.clinica);
          self.$setState({
            clinicas
          }, () => {
            self.$root.noResultCheck(clinicas);
            app.popup.open(popupEl);
          });
        }, 'preloader');
      } else {
        let options = {
          ativo: true,
          data_aprovacao: {
            $exists: true
          }
        };
        self.$root.request('get', '/listar/clinicas', options, res => {
          let {
            clinicas
          } = res;
          self.$setState({
            clinicas
          }, () => {
            self.$root.noResultCheck(clinicas);
            app.popup.open(popupEl);
          });
        }, 'preloader');
      }
    },

    mostrarVeterinarios() {
      const self = this,
            popupEl = self.$el.find('.popup-veterinarios'),
            clinica_id = self.clinica._id;

      if (clinica_id) {
        self.$root.request('get', '/listar/vinculos', {
          clinica_id,
          select: ['vinculados']
        }, res => {
          let veterinarios = res.vinculos.map(i => i.veterinario);
          self.$setState({
            veterinarios
          }, () => {
            self.$root.noResultCheck(veterinarios);
            app.popup.open(popupEl);
          });
        }, 'preloader');
      } else {
        let options = {
          ativo: true,
          data_aprovacao: {
            $exists: true
          }
        };
        self.$root.request('get', '/listar/veterinarios', options, res => {
          let {
            veterinarios
          } = res;
          self.$setState({
            veterinarios
          }, () => {
            self.$root.noResultCheck(veterinarios);
            app.popup.open(popupEl);
          });
        }, 'preloader');
      }
    },

    mostrarAnimais() {
      const self = this,
            popupEl = self.$el.find('.popup-animais');
      this.$setState({
        searching_animal: false
      }, () => {
        app.popup.open(popupEl);
      });
    },

    handleSelecionarClinica(e) {
      let id = $(e.target).closest('tr').data('id-registro');
      let clinica = this.clinicas.find(i => i._id == id);
      this.$setState({
        clinica
      }, () => app.popup.close());
    },

    handleSelecionarVeterinario(e) {
      let id = $(e.target).closest('tr').data('id-registro');
      let veterinario = this.veterinarios.find(i => i._id == id);
      this.$setState({
        veterinario
      }, () => app.popup.close());
    },

    handleSelecionarAnimal(e) {
      let id = $(e.target).closest('tr').data('id-registro');
      let animal = this.animais.find(i => i._id == id);
      this.$setState({
        animal
      }, () => app.popup.close());
    },

    buscarAnimais(e) {
      e.preventDefault();
      const self = this,
            options = {
        ativo: true
      };
      self.$setState({
        searching_animal: true
      });
      const value = $(e.target).find('input').val(),
            cpf_tutor = String.getOnlyNumbers(value);
      const v = {
        $regex: value.trim(),
        $options: 'i'
      },
            nome_paciente = v,
            nome_tutor = v;
      const query = {
        $or: [{
          nome_paciente
        }, {
          nome_tutor
        }, {
          cpf_tutor
        }]
      };
      self.$root.request('get', `/listar/animais`, { ...query,
        ...options
      }, ({
        animais
      }) => {
        self.$setState({
          animais
        }, () => self.$root.noResultCheck(animais));
      }, 'preloader');
    },

    cadastrarExame(e) {
      e.preventDefault();
      const self = this;
      const formEl = self.$el.find('form');
      const formData = app.form.convertToData(formEl);
      formData.urgente = formData.urgente.length ? true : false;
      formData.exames = formData.exames.map(i => app.utils.parseUrlQuery('?' + i));
      if (self.$root.user_account.tipo_cadastro == 3) formData.data_aprovacao = new Date();
      if (!formData.veterinario) app.dialog.alert('Favor, selecione um veterinário para prosseguir.', 'Nenhum veterinário foi selecionado');else if (!formData.animal) app.dialog.alert('Favor, selecione um animal para prosseguir.', 'Nenhum animal foi selecionado');else if (formData.exames.length == 0) app.dialog.alert('Favor, selecione no mínimo 1 exame para prosseguir.', 'Nenhum exame foi selecionado');else self.$root.request('post', '/cadastrar/exame', formData, res => {
        self.$router.navigate('/exames');
      }, 'preloader');
    },

    checkAll(e) {
      $(e.target).closest('.checkbox-container').find('[type="checkbox"]').prop('checked', e.target.checked);
    },

    check(e) {
      let checkboxes = jQuery(e.target).closest('.checkbox-container').find('[type="checkbox"]');
      let totalChecked = checkboxes.not(':first').filter((index, el) => el.checked == true).length;
      checkboxes.first().prop('indeterminate', totalChecked > 0 && totalChecked < checkboxes.length - 1);
      checkboxes.first().prop('checked', totalChecked == checkboxes.length - 1);
    },

    mostrarDadosDaClinica() {
      const popupEl = this.$el.find('.popup-clinica'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.clinica);
      app.popup.open(popupEl);
    },

    mostrarDadosDoVeterinario() {
      const popupEl = this.$el.find('.popup-veterinario'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.veterinario);
      app.popup.open(popupEl);
    },

    mostrarDadosDoAnimal() {
      const popupEl = this.$el.find('.popup-animal'),
            formEl = popupEl.find('form');
      app.form.fillFromData(formEl, this.animal);
      app.popup.open(popupEl);
    },

    handleExameSemClinica(e) {
      this.$el.find('.item-input.clinica')[(e.target.checked ? 'add' : 'remove') + 'Class']('disabled');
      if (e.target.checked) this.$setState({
        clinica: {}
      });
    },

    cadastrarNovoAnimal() {
      window.open('/animais/tutor/0/cadastrar', '_blank');
    }

  },
  id: '69afc2d9bd',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += ' <a href=/exames class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a></div><div class=block><a href=/exames>Exames</a> <span>/</span> <a href=#>Cadastrar</a></div></div><div class=card><div class="card-content card-content-padding"><form @submit=cadastrarExame><div class="row no-gap"><div class=col-50><div class="list inline-labels no-hairlines no-hairlines-between"><ul>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content"><input type=checkbox @change=handleExameSemClinica> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Exame sem vínculo com uma clínica</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li class="item-content item-input clinica" key=';
      r += c(ctx_1.clinica._id, ctx_1);
      r += '><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Clínica</div><div class=item-input-wrap><input type=hidden name=clinica value=';
      r += c(ctx_1.clinica._id, ctx_1);
      r += '><div class=row><a href=# class="item-link item-content" @click=mostrarClinicas style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += Template7Helpers.js.call(ctx_1, "this.clinica.nome_fantasia || 'Selecione'", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.clinica.cnpj, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=item-text>CNPJ: <span class=cnpj>';
          r += c(ctx_2.clinica.cnpj, ctx_2);
          r += '</span></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></a><a class="button tooltip-init ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.clinica.cnpj', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click=mostrarDadosDaClinica href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li><li class="item-content item-input" key=';
      r += c(ctx_1.animal._id, ctx_1);
      r += '><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Animal</div><div class=item-input-wrap><input type=hidden name=animal value=';
      r += c(ctx_1.animal._id, ctx_1);
      r += '><div class=row><a href=# class="item-link item-content" @click=mostrarAnimais style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += Template7Helpers.js.call(ctx_1, "this.animal.nome_paciente || 'Selecione'", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.animal.cpf_tutor, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=item-text>CPF do tutor: <span class=cpf>';
          r += c(ctx_2.animal.cpf_tutor, ctx_2);
          r += '</span></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></a><a class="button tooltip-init ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.animal.cpf_tutor', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click=mostrarDadosDoAnimal href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content"><input type=checkbox name=urgente value=true> <i class="icon icon-checkbox color-red"></i><div class="item-inner text-color-red"><div class=item-title>URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class=col-50><div class="list inline-labels no-hairlines no-hairlines-between"><ul>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content"><input type=checkbox name=urgente value=true> <i class="icon icon-checkbox color-red"></i><div class="item-inner text-color-red"><div class=item-title>URGÊNCIA (será cobrado valor da taxa de R$ 7,00)</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li class="item-content item-input veterinario" key=';
      r += c(ctx_1.veterinario._id, ctx_1);
      r += '><div class=item-inner><div class="item-title item-label w-100px mt-5 f-bold">Veterinário</div><div class=item-input-wrap><input type=hidden name=veterinario value=';
      r += c(ctx_1.veterinario._id, ctx_1);
      r += '><div class=row><a href=# class="item-link item-content" @click=mostrarVeterinarios style="width: calc(100% - 50px);"><div class=item-inner><div class=item-title>';
      r += Template7Helpers.js.call(ctx_1, "this.veterinario.nome || 'Selecione'", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.veterinario.cpf, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=item-text>CPF: <span class=cpf>';
          r += c(ctx_2.veterinario.cpf, ctx_2);
          r += '</span></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></a><a class="button tooltip-init ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.veterinario.cpf', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" @click=mostrarDadosDoVeterinario href=# data-tooltip=Visualizar><i class="icon mdi mdi-eye"></i></a></div></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label w-150px mt-5 f-bold">Suspeita clínica</div><div class=item-input-wrap><input type=text name=suspeita_clinica> <span class=input-clear-button></span></div></div></li></ul></div></div></div><div class="block-title f-bold fs-16 mt-25 mb-30">Exames</div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.formulario_de_exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list checkbox-container m-16 ';
          r += Template7Helpers.js_if.call(ctx_2, '!@first', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'border-top';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><ul><li class="bg-color-light-gray border-radius mt-16"><label class="item-checkbox item-content border-radius"><input type=checkbox @change=checkAll> <i class="icon icon-checkbox"></i><div class=item-inner><div class="item-title f-bold">';
          r += c(ctx_2.topico, ctx_2);
          r += '</div></div></label><div class=row>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.exames, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="col-';
              r += Template7Helpers.js_if.call(ctx_3, '../exames.length == 2', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '50';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '100';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '"><div class="list no-hairlines-between"><ul>';
              r += Template7Helpers.each.call(ctx_3, ctx_3, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><label class="item-checkbox item-content border-radius tooltip-init" data-tooltip="';
                  r += Template7Helpers.js.call(ctx_4, 'String(this.nome)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '"><input @change=check type=checkbox name=exames value=\'';
                  r += Template7Helpers.js.call(ctx_4, "app.utils.serializeObject(this)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\'> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>';
                  r += c(ctx_4.nome, ctx_4);
                  r += '</div></div></label></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="block mt-30 mb-15"><button type=submit class="button button-fill button-large">Cadastrar</button></div></form></div></div></div><div class="popup popup-clinicas" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class="page page-with-subnavbar"><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Clínicas</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div><div class=subnavbar><form class=searchbar><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Pesquisar @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class=searchbar-disable-button>Cancel</span></div></form></div></div></div><div class="page-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=clinicas><thead><tr><th @click=$root.ordenar data-key-name=nome_fantasia class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome fantasia</th><th @click=$root.ordenar data-key-name=cnpj class="label-cell sortable-cell sortable-asc">CNPJ</th><th @click=$root.ordenar data-key-name=cidade class="label-cell sortable-cell sortable-asc">Cidade</th><th class=numeric-cell>Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.clinicas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.nome_fantasia, ctx_2);
          r += '</td><td class="label-cell cnpj">';
          r += c(ctx_2.cnpj, ctx_2);
          r += '</td><td class=label-cell>';
          r += c(ctx_2.cidade, ctx_2);
          r += '</td><td class=numeric-cell><a href=# @click=handleSelecionarClinica data-tooltip=Selecionar class="button button-small tooltip-init center color-primary float-right"><i class="icon mdi mdi-plus-circle fs-22"></i></a></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div></div><div class="popup popup-veterinarios" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class="page page-with-subnavbar"><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Veterinários</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div><div class=subnavbar><form class=searchbar><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Pesquisar @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class=searchbar-disable-button>Cancel</span></div></form></div></div></div><div class="page-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=veterinarios><thead><tr><th @click=$root.ordenar data-key-name=nome class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome</th><th @click=$root.ordenar data-key-name=cpf class="label-cell sortable-cell sortable-asc">CPF</th><th @click=$root.ordenar data-key-name=crmv class="label-cell sortable-cell sortable-asc">CRMV</th><th class=numeric-cell>Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.veterinarios, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.nome, ctx_2);
          r += '</td><td class="label-cell cpf">';
          r += c(ctx_2.cpf, ctx_2);
          r += '</td><td class=label-cell>';
          r += c(ctx_2.crmv, ctx_2);
          r += '</td><td class=numeric-cell><a href=# @click=handleSelecionarVeterinario data-tooltip=Ativar class="button button-small tooltip-init center color-primary float-right"><i class="icon mdi mdi-plus-circle fs-22"></i></a></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div></div><div class="popup popup-animais" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Animais</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form @submit=buscarAnimais class="list inline-labels no-hairlines"><ul class=no-bg><li class="item-content item-input"><div class=item-inner><!-- <div class="item-title item-label w-auto mr-20">Insira o CPF do tutor do animal</div> --><div class="item-input-wrap mr-20"><input type=text placeholder="Insira o CPF do tutor, nome do tutor ou nome do animal" required> <span class=input-clear-button></span></div><button type=submit class="button button-fill w-400px"><i class="icon mdi mdi-magnify mr-10"></i> Buscar animais</button></div></li></ul></form>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.searching_animal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div style="border-top: 1px solid rgba(0,0,0,0.1);"></div><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div>';
          r += Template7Helpers.js_if.call(ctx_2, "this.animais.length == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=center><button type=button @click=cadastrarNovoAnimal class="button button-outline w-400px">Cadastrar novo animal</button></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class="data-table data-table-init"><table data-array-name=animais><thead><tr><th @click=$root.ordenar data-key-name=nome_paciente class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome</th><th @click=$root.ordenar data-key-name=raca class="label-cell sortable-cell sortable-asc">Raça</th><th @click=$root.ordenar data-key-name=nome_tutor class="label-cell sortable-cell sortable-asc">Nome do tutor</th><th @click=$root.ordenar data-key-name=cpf_tutor class="label-cell sortable-cell sortable-asc">CPF do tutor</th><th class=numeric-cell>Ações</th></tr></thead><tbody>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.animais, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<tr data-id-registro=';
              r += c(ctx_3._id, ctx_3);
              r += '><td class=label-cell>';
              r += c(ctx_3.nome_paciente, ctx_3);
              r += '</td><td class=label-cell>';
              r += c(ctx_3.raca, ctx_3);
              r += '</td><td class=label-cell>';
              r += c(ctx_3.nome_tutor, ctx_3);
              r += '</td><td class="label-cell cpf">';
              r += c(ctx_3.cpf_tutor, ctx_3);
              r += '</td><td class=numeric-cell><a href=# @click=handleSelecionarAnimal data-tooltip=Selecionar class="button button-small tooltip-init center color-primary float-right"><i class="icon mdi mdi-plus-circle fs-22"></i></a></td></tr>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</tbody></table></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div><div class="popup popup-clinica" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados da clínica</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div><div class="popup popup-veterinario" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados do veterinário</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.veterinario, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div><div class="popup popup-animal" style="--f7-popup-tablet-width: 1024px;"><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Dados do animal</div><div class=right><a class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><form class="block mt-0"><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.animal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul class=no-bg>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  [data-tooltip="Visualizar"] {
    border-radius: 0px;
    line-height: 45px;
    height: auto;
    width: 50px;
  }
[data-f7-69afc2d9bd] .border-top {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
`,
  styleScoped: true
};
    