
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'visualizar-clinica',
      title: 'Visualizar clínica',
      loading_vinculos: true,
      loading: false,
      vinculos: []
    };
  },

  mounted() {
    const self = this,
          {
      clinica_id
    } = self.$route.params;
    self.$root.request('get', `/buscar/clinica/${clinica_id}`, null, res => {
      self.$tick(() => {
        let formEl = self.$el.find('form');
        app.form.fillFromData(formEl, res.clinica);
        self.$root.maskInputs();
      });
    }, 'loading');
    self.listarVinculos();
  },

  methods: {
    listarVinculos() {
      const self = this,
            {
        clinica_id
      } = self.$route.params;
      self.$root.request('get', '/listar/vinculos', {
        clinica_id
      }, res => {
        let {
          vinculos
        } = res;
        self.$setState({
          vinculos,
          loading_vinculos: false
        });
      });
    }

  },
  id: '5dd02f6e76',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += ' <a href=/clinicas class="button float-right center"><i class="icon mdi mdi-arrow-left mr-5"></i> Voltar</a></div><div class=block><a href=/clinicas>Clínicas</a> <span>/</span> <a href=#>Visualizar</a></div></div><div class=card>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content card-content-padding"><form class=mb-15><div class=row>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.$root.campos.clinica, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col><div class="list inline-labels no-hairlines"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">';
              r += c(ctx_3.label, ctx_3);
              r += '</div><div class=item-input-wrap><input type=';
              r += c(ctx_3.type, ctx_3);
              r += ' name=';
              r += c(ctx_3.name, ctx_3);
              r += ' readonly=readonly></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div><div class="card mt-30"><div class="card-header fs-20">Veterinários vinculados</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading_vinculos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content card-content-padding">';
      r += Template7Helpers.js_if.call(ctx_1, "this.vinculos.length == 0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block center p-22 no-result">Nenhum registro foi encontrado!</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="list animais"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.vinculos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/clinicas/visualizar/';
          r += c(ctx_1.$route.params.clinica_id, ctx_2);
          r += '/visualizar-veterinario-vinculado/';
          r += c(ctx_2.veterinario._id, ctx_2);
          r += ' class="item-link item-content"><div class=item-media><div class="profile center"><i class="icon mdi mdi-account fs-30 -mt-1 -ml-1"></i></div></div><div class=item-inner><div class="item-title fs-16">';
          r += c(ctx_2.veterinario.nome, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .profile {
    background-color: var(--f7-theme-color);
    border-radius: 50%;
    color: #fff;
    height: 50px;
    width: 50px;
  }
`,
  styleScoped: false
};
    