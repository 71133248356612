export default {
  data() {
    return {
      name: '404',
      title: '404 - Página não encontrada'
    };
  },

  id: '0e0bbda6ca',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class="card-content card-content-padding"><p>Desculpe! :(</p><p>A página que você procura não foi encontrada.</p></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};