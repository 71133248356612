
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'veterinarios',
      title: 'Veterinários',
      loading: false,
      veterinarios: []
    };
  },

  mounted() {
    this.listarRegistros({
      ativo: true
    });
  },

  updated() {
    this.$root.maskInputs();
  },

  methods: {
    listarRegistros(options = {}) {
      const self = this;
      options.data_aprovacao = {
        $exists: true
      };
      self.$root.request('get', '/listar/veterinarios', options, res => {
        let {
          veterinarios
        } = res;
        self.$setState({
          veterinarios
        }, () => {
          self.$root.noResultCheck(veterinarios);
        });
      }, 'loading');
    }

  },
  id: '79eec70eda',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class=card-header><div class=center><form class="searchbar searchbar-custom"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar class=w-300px @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button p-absolute left-0">Cancel</span></div></form><div class="list filtro"><ul><li><a class="item-link smart-select smart-select-init elevation-1" data-open-in=popover data-routable-modals=false data-close-on-select=true><select name=ativo @change=$root.handleFiltro><option value=true selected=selected>Ativos</option><option value=false>Inativos</option></select><div class=item-content><div class=item-inner><div class=item-title>Filtro:</div></div></div></a></li></ul></div><div class=status-content><span class="badge status color-green"></span> Ativos<br><span class="badge status color-red"></span> Inativos<br></div></div><a href=/veterinarios/cadastrar data-tooltip=Cadastrar class="button button-raised tooltip-init center w-auto"><i class="icon mdi mdi-plus"></i></a></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=veterinarios><thead><tr><th @click=$root.ordenar data-key-name=nome class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome</th><th @click=$root.ordenar data-key-name=cpf class="label-cell sortable-cell sortable-asc">CPF</th><th @click=$root.ordenar data-key-name=crmv class="label-cell sortable-cell sortable-asc">CRMV</th><th @click=$root.ordenar data-key-name=status class="label-cell sortable-cell sortable-asc">Status</th><th class="numeric-cell pr-82">Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.veterinarios, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.nome, ctx_2);
          r += '</td><td class="label-cell cpf">';
          r += c(ctx_2.cpf, ctx_2);
          r += '</td><td class=label-cell>';
          r += c(ctx_2.crmv, ctx_2);
          r += '</td><td class=label-cell><span class="badge status color-';
          r += Template7Helpers.if.call(ctx_2, ctx_2.ativo, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'red';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></span></td><td class=numeric-cell><div class="segmented float-right w-100px -mr-5"><a href=/veterinarios/visualizar/';
          r += c(ctx_2._id, ctx_2);
          r += ' data-tooltip=Visualizar class="button button-small tooltip-init center color-gray"><i class="icon mdi mdi-eye"></i></a> ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.ativo, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=/veterinarios/editar/';
              r += c(ctx_3._id, ctx_3);
              r += ' data-tooltip=Editar class="button button-small tooltip-init center color-blue"><i class="icon mdi mdi-pencil"></i></a> <a href=# @click=$root.inativarRegistro data-tooltip=Inativar class="button button-small tooltip-init center color-red"><i class="icon mdi mdi-minus-circle fs-22"></i></a> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# @click=$root.ativarRegistro data-tooltip=Ativar class="button button-small tooltip-init center color-primary"><i class="icon mdi mdi-plus-circle fs-22"></i></a> ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    