
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      name: 'modelos-de-exames',
      title: 'Modelos de exames',
      loading: false,
      exame_id: null,
      exames: []
    };
  },

  mounted() {
    const self = this;
    self.$root.request('get', '/listar/formulario-de-exames', {
      modelos_de_exames: true
    }, res => {
      res.formulario_de_exames.forEach(({
        exames
      }) => {
        exames.forEach(exame => self.exames.push(exame));
      });
      self.$update();
    }, 'loading');
  },

  methods: {
    handleSelectModelo({
      target,
      type
    }) {
      const self = this;
      self.$el.find('[type="file"]').click();
      self.exame_id = $(target).closest('tr').data('id-registro');
      self.exame_index = $(target).closest('tr').data('index-registro');
    },

    handleUploadModelo({
      target
    }) {
      const self = this,
            [file] = target.files;
      self.$root.fileToBase64UrlData(file).then(arquivo_modelo => {
        const {
          exame_id,
          exame_index
        } = self;
        target.value = null;
        self.$root.request('put', '/upload-modelo-de-exame', {
          arquivo_modelo,
          exame_id
        }, res => {
          self.exames[exame_index].modelo_de_exame = res.modelo_de_exame;
          self.$update();
        }, 'preloader');
      }).catch(err => {
        app.dialog.alert('Entre em contato com o suporte.', 'Falha na aplicação!');
      });
    },

    handleRemoverModelo({
      target
    }) {
      const self = this;
      const exame_id = $(target).closest('tr').data('id-registro');
      const exame_index = $(target).closest('tr').data('index-registro');
      const modelo_de_exame_id = self.exames[exame_index].modelo_de_exame._id;
      self.$root.request('delete', `/deletar-modelo-de-exame/${modelo_de_exame_id}`, null, res => {
        self.exames[exame_index].modelo_de_exame = null;
        self.$update();
      }, 'preloader');
    },

    handleBaixarModelo({
      target
    }) {
      const exame_index = $(target).closest('tr').data('index-registro');
      const {
        arquivo_modelo
      } = this.exames[exame_index].modelo_de_exame;
      this.$root.downloadFileBase64UrlData(arquivo_modelo, Date.now() + '.xls');
    },

    copyTextToClipboard(text) {
      const textareaEl = jQuery('<textarea>').val(text).appendTo('body').select();
      document.execCommand('copy');
      textareaEl.remove();
    } // handleVisualizarModelo({ target }) {
    //   const exame_index = $(target).closest('tr').data('index-registro');
    //   const { arquivo_modelo } = this.exames[exame_index].modelo_de_exame;
    //   // this.$root.base64UrlDataToFile({
    //   //   data: arquivo_modelo,
    //   //   name: Date.now() + '.xls',
    //   //   type: 'application/vnd.ms-excel'
    //   // }).then(file => {
    //   //   console.log(file);
    //   // });
    //   // http://www.learningaboutelectronics.com/Articles/Example.xlsx
    //   // window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${ arquivo_modelo }`, '_blank');
    //   // window.open(`https://docs.google.com/viewer?url=${ arquivo_modelo }&embedded=true`, '_blank');
    //   window.open(`https://docs.google.com/viewerng/viewer?url=${ arquivo_modelo }`, '_blank');
    // },


  },
  id: 'ecacf996b8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class=card-header><div class=center><form class="searchbar searchbar-custom"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar class=w-300px @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button p-absolute left-0">Cancel</span></div></form><div class="list filtro"><ul><li><a class="item-link smart-select smart-select-init elevation-1" data-open-in=popover data-routable-modals=false data-close-on-select=true><!-- @change="$root.handleFiltro" --> <select name=ativo><option value=true selected=selected>Modelo disponível</option><option value=false>Modelo pendente</option></select><div class=item-content><div class=item-inner><div class=item-title>Filtro:</div></div></div></a></li></ul></div><div class=status-content><span class="badge status color-green"></span> Modelo disponível<br><span class="badge status color-gray st"></span> Modelo pendente<br></div></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=exames><thead><tr><th @click=$root.ordenar data-key-name=nome class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome</th><th @click=$root.ordenar data-key-name=status class="label-cell sortable-cell sortable-asc">Status</th><th class="numeric-cell pr-100">Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += ' data-index-registro=';
          r += c(data_2 && data_2.index, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.nome, ctx_2);
          r += '</td><td class="label-cell w-100px"><span class="badge status color-';
          r += Template7Helpers.if.call(ctx_2, ctx_2.modelo_de_exame, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'gray st';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></span></td><td class="numeric-cell w-200px"><div class="segmented float-right -mr-5"><!-- <a href="#" @click="handleVisualizarModelo" data-tooltip="Visualizar modelo" class="button button-small tooltip-init color-gray ';
          r += Template7Helpers.js_if.call(ctx_2, '!this.modelo_de_exame', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><i class="icon mdi mdi-file-eye"></i></a> --> <a href=# @click=handleBaixarModelo data-tooltip="Baixar modelo" class="button button-small tooltip-init color-primary ';
          r += Template7Helpers.js_if.call(ctx_2, '!this.modelo_de_exame', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><i class="icon mdi mdi-file-download"></i></a> <a href=# @click=handleSelectModelo data-tooltip="Upload do modelo" class="button button-small tooltip-init color-primary"><i class="icon mdi mdi-file-upload"></i></a> <a href=# @click=handleRemoverModelo data-tooltip="Remover do modelo" class="button button-small tooltip-init color-red ';
          r += Template7Helpers.js_if.call(ctx_2, '!this.modelo_de_exame', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><i class="icon mdi mdi-file-remove"></i></a></div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div><input type=file accept=.xlsx class=d-none @change=handleUploadModelo></div></div></div>';
      return r;
    }(this);
  },

  style: `
[data-f7-ecacf996b8] .status-content {
    margin-right: 100px;
  }
`,
  styleScoped: true
};
    