
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      // import XLSX from 'xlsx';
export default {
  data() {
    return {
      name: 'exames',
      title: 'Exames',
      loading: false,
      exames: [],
      options: {
        ativo: true
      },
      popup: {
        exame: {
          resultados: [],
          animal: {}
        },
        modelos_de_exames: []
      }
    };
  },

  mounted() {
    const self = this,
          {
      tipo_cadastro,
      usuario_id
    } = self.$root.user_account;
    if (tipo_cadastro == 3) self.listarRegistros(self.options);else self.$root.request('get', `/buscar/usuario/${usuario_id}`, null, res => {
      const param = tipo_cadastro == 1 ? 'clinica' : 'veterinario';
      self.options[param] = res.dados_cadastro._id;
      self.$update();
    }, 'loading', null, () => {
      self.listarRegistros(self.options);
    });
    let date = new Date();
    app.calendar.create({
      inputEl: '#data-liberacao',
      closeOnSelect: true,
      disabled: {
        to: date.setDate(date.getDate() - 1)
      }
    });
  },

  updated() {
    this.$root.maskInputs();
  },

  methods: {
    listarRegistros(options = {}) {
      const self = this,
            {
        tipo_cadastro
      } = self.$root.user_account;
      if (tipo_cadastro == 3) options.data_aprovacao = {
        $exists: true
      };
      self.$root.request('get', '/listar/exames', {
        select: '-resultados.file_base64_url_data',
        ...options
      }, ({
        exames
      }) => {
        exames.map(exame => {
          exame.nome_fantasia_clinica = (exame.clinica || {}).nome_fantasia;
          exame.nome_paciente_animal = exame.animal.nome_paciente;
          exame.nome_veterinario = exame.veterinario.nome;
          exame.data_solicitacao = exame.data_inclusao;
          exame.resultados = Array.sortObjects(exame.resultados, 'data_inclusao', 'des');
        });
        self.$setState({
          exames
        }, () => {
          self.$root.noResultCheck(exames);
          self.updateStatusExames({
            force: true
          });
        });
      }, 'loading');
    },

    updateStatusExames(options = {
      force: true
    }) {
      this.exames.map(exame => {
        exame.statusColor = '';
        if (exame.data_aprovacao) exame.statusColor = 'yellow'; // Aguardando resultado

        if (exame.data_aprovacao && exame.pendente) exame.statusColor = 'lightblue'; // Aprovado com pendência

        if (exame.resultados.length) exame.statusColor = 'green'; // Resultado disponível

        if (!exame.data_aprovacao) exame.statusColor = 'purple'; // Pendente de aprovação
      });
      if (options.force) this.$update();
    },

    handleFiltro(e) {
      const self = this,
            {
        tipo_cadastro
      } = self.$root.user_account;
      let options = Object.clone(self.options);
      let value = Number(e.target.value);

      switch (value) {
        case 1:
          options.filter = 'aguardando_resultado';
          break;

        case 2:
          options.filter = 'resultado_disponivel';
          break;

        case 3:
          options.filter = 'aprovado_com_pendencia';
          break;

        case 4:
          options.filter = 'pendente_de_aprovacao';
          break;

        case 5:
          options.filter = 'urgente';
          break;
      }

      self.listarRegistros(options);
    },

    handleResultados(e) {
      const self = this,
            exame_id = $(e.target).closest('tr').data('id-registro');
      self.$root.request('get', `/buscar/exame/${exame_id}`, null, res => {
        const popupEl = self.$el.find('#resultados');
        self.popup.exame = res.exame;
        self.$update(() => app.popup.open(popupEl));
      }, 'preloader');
    },

    salvarDataLiberacao() {
      const self = this,
            inputEl = $('.popup.modal-in').find('input#data-liberacao');
      if (!inputEl.val()) app.dialog.alert(null, 'Favor, selecione uma data para salvar este campo.');else {
        let data_liberacao = moment(inputEl.val(), 'DD/MM/YYYY').toISOString(),
            exame_id = self.popup.exame._id;
        self.$root.request('put', `/editar/exame/${exame_id}`, {
          data_liberacao
        }, () => {
          self.popup.exame.data_liberacao = data_liberacao;
          self.$update();
        }, 'preloader');
      }
    },

    handleUploadFile(e) {
      const self = this,
            file = e.target.files[0],
            exame_id = self.popup.exame._id;
      self.$root.fileToBase64UrlData(file).then(file_base64_url_data => {
        let {
          resultados
        } = self.popup.exame;
        resultados.push({
          file_base64_url_data
        });
        self.$root.request('post', `/publicar-resultado/exame/${exame_id}`, {
          resultados
        }, res => {
          const resultadosOrdenados = Array.sortObjects(res.exame.resultados, 'data_inclusao', 'des');
          let exame = self.exames.find(i => i._id == res.exame._id);
          if (exame) exame.resultados = resultadosOrdenados;
          self.popup.exame.resultados = resultadosOrdenados;
          self.updateStatusExames({
            force: true
          });
        }, 'preloader');
      }).catch(err => {
        app.dialog.alert('Entre em contato com o suporte.', 'Falha na aplicação!');
      });
    },

    handleSelectFile(e) {
      $(e.target).parent().find('input[type="file"]').val('').click();
    },

    actionResultado(e) {
      const self = this,
            resultado_id = $(e.target).closest('tr').data('id-registro');
      let {
        resultados
      } = self.popup.exame,
          resultado = resultados.find(i => i._id == resultado_id);
      let action = $(e.target).closest('.button').data('action'),
          exame_id = self.popup.exame._id;
      if (action == 'visualizar') window.open('', '_blank').document.write(`<html><body style="margin: 0px"><iframe src="${resultado.file_base64_url_data}" width="100%" height="100%" frameBorder="0"></iframe></body></html>`);
      if (action == 'baixar') self.$root.downloadFileBase64UrlData(resultado.file_base64_url_data, resultado._id + '.pdf');

      if (action == 'remover') {
        resultados = resultados.filter(i => i._id != resultado_id);
        self.$root.request('put', `/editar/exame/${exame_id}`, {
          resultados
        }, res => {
          const resultadosOrdenados = Array.sortObjects(res.exame.resultados, 'data_inclusao', 'des');
          let exame = self.exames.find(i => i._id == res.exame._id);
          if (exame) exame.resultados = resultadosOrdenados;
          self.popup.exame.resultados = resultadosOrdenados;
          self.updateStatusExames({
            force: true
          });
        }, 'preloader');
      }
    },

    exportarExcel({
      target
    }) {
      const exame_index = $(target).closest('tr').data('index-registro');
      const self = this,
            exame_id = self.exames[exame_index]._id;
      self.$root.request('get', `/obter-modelos-dos-exames/${exame_id}`, null, ({
        exames
      }) => {
        self.popup.modelos_de_exames = exames;
        self.$update(() => {
          app.popup.open('#exportal-exames');
        });
        console.log(exames);
      }, 'preloader');
    },

    // self.popup.exame = exame;
    handleBaixarModeloDeExame({
      target
    }) {
      const exame_index = $(target).closest('tr').data('index-registro');
      const {
        arquivo_modelo
      } = this.popup.modelos_de_exames[exame_index].modelo_de_exame;
      this.$root.downloadFileBase64UrlData(arquivo_modelo, Date.now() + '.xlsx');
    } // handleVisualizarModeloDeExame({ target }) {
    //   const exame_index = $(target).closest('tr').data('index-registro');
    //   const { arquivo_modelo } = this.popup.modelos_de_exames[exame_index].modelo_de_exame;
    //   window.open(`https://docs.google.com/viewerng/viewer?url=${ arquivo_modelo }`, '_blank');
    // },


  },
  id: 'de8c729a7e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=';
      r += c(ctx_1.name, ctx_1);
      r += '><div class=page-content><div class=breadcrumb><div class="block-title block-title-medium">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div><div class=card><div class=card-header><div class=center><form class="searchbar searchbar-custom"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar class=w-300px @input=$root.search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button p-absolute left-0">Cancel</span></div></form><div class="list filtro"><ul><li><a class="item-link smart-select smart-select-init elevation-1" data-open-in=popover data-routable-modals=false data-close-on-select=true><select name=ativo @change=handleFiltro><option value=0 selected=selected>Todos</option><option value=1>Aguardando resultado</option><option value=2>Resultado disponível</option><option value=3>Aprovado com pendência</option>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value=4>Pendente de aprovação</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<option value=5>Urgente</option></select><div class=item-content><div class=item-inner><div class=item-title>Filtro:</div></div></div></a></li></ul></div><div class=status-content><span class="badge status color-green"></span> Resultado disponível<br><span class="badge status color-yellow"></span> Aguardando resultado<br></div><div class=status-content>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <span class="badge status color-red min-w-auto min-h-auto p-5"></span> Urgente<br>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <span class="badge status color-purple"></span> Pendente de aprovação<br>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <span class="badge status color-lightblue"></span> Aprovado com pendência<br></div>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro != 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=status-content><span class="badge status color-red min-w-auto min-h-auto p-5"></span> Urgente<br></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><a href=/exames/cadastrar data-tooltip=Cadastrar class="button button-raised tooltip-init center w-auto"><i class="icon mdi mdi-plus"></i></a></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=progressbar-infinite></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content search-container"><div class="no-result d-none"><div class="block center p-25">Nenhum registro foi encontrado!</div></div><div class="data-table data-table-init"><table data-array-name=exames><thead><tr><th @click=$root.ordenar data-key-name=num_os class="label-cell sortable-cell sortable-asc sortable-cell-active">Nº da OS</th><th @click=$root.ordenar data-key-name=nome_fantasia_clinica class="label-cell sortable-cell sortable-asc">Clínica</th><th @click=$root.ordenar data-key-name=nome_veterinario class="label-cell sortable-cell sortable-asc">Veterinário</th><th @click=$root.ordenar data-key-name=nome_paciente_animal class="label-cell sortable-cell sortable-asc">Animal</th><th @click=$root.ordenar data-key-name=urgente class="label-cell sortable-cell sortable-asc">Urgência</th><th @click=$root.ordenar data-key-name=status class="label-cell sortable-cell sortable-asc">Status</th><th @click=$root.ordenar data-key-name=data_solicitacao class="label-cell sortable-cell sortable-asc">Data da solicitação</th><th @click=$root.ordenar data-key-name=data_liberacao class="label-cell sortable-cell sortable-asc">Data prevista de liberação</th><th class="numeric-cell ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.user_account.tipo_cadastro == 3', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'pr-130';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'pr-70';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += ' data-index-registro=';
          r += c(data_2 && data_2.index, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.num_os, ctx_2);
          r += '</td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "this.nome_fantasia_clinica || '--'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=label-cell>';
          r += c(ctx_2.nome_veterinario, ctx_2);
          r += '</td><td class=label-cell>';
          r += c(ctx_2.nome_paciente_animal, ctx_2);
          r += '</td><td class=label-cell><span class="badge status color-';
          r += Template7Helpers.if.call(ctx_2, ctx_2.urgente, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'red';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'gray st';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' min-w-auto min-h-auto p-5"></span></td><td class=label-cell><span class="badge status color-';
          r += c(ctx_2.statusColor, ctx_2);
          r += '"></span></td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "moment(this.data_solicitacao).format('DD/MM/YYYY HH:mm')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "this.data_liberacao ? moment(this.data_liberacao).format('DD/MM/YYYY') : '--'", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=numeric-cell><div class="segmented float-right -mr-5 ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.user_account.tipo_cadastro == 3', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'w-150px';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'w-100px';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">';
          r += Template7Helpers.js_if.call(ctx_2, "../$root.user_account.tipo_cadastro == 3", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# @click=exportarExcel data-tooltip="Exportar p/Excel" class="button button-small tooltip-init center color-teal"><i class="icon mdi mdi-microsoft-excel"></i></a> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' <a href=# @click=handleResultados data-tooltip=Resultados class="button button-small tooltip-init center color-primary"><i class="icon mdi mdi-text-box-check"></i></a> <a href=/exames/visualizar/';
          r += c(ctx_2._id, ctx_2);
          r += ' data-tooltip=Visualizar class="button button-small tooltip-init center color-gray"><i class="icon mdi mdi-eye"></i></a> ';
          r += Template7Helpers.js_if.call(ctx_2, "../$root.user_account.tipo_cadastro == 3", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=/exames/editar/';
              r += c(ctx_3._id, ctx_3);
              r += ' data-tooltip=Editar class="button button-small tooltip-init center color-blue"><i class="icon mdi mdi-pencil"></i></a> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div><div class=popup id=resultados><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Resultados</div><div class=right><a href=# class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><div class="list inline-labels no-hairlines mt-15 mb-0"><ul class=no-bg><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome do animal</div><div class=item-input-wrap><input type=text value=';
      r += c(ctx_1.popup.exame.animal.nome_paciente, ctx_1);
      r += ' disabled=disabled></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome do tutor</div><div class=item-input-wrap><input type=text value=';
      r += c(ctx_1.popup.exame.animal.nome_tutor, ctx_1);
      r += ' disabled=disabled></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">CPF do tutor</div><div class=item-input-wrap><input type=text value=';
      r += c(ctx_1.popup.exame.animal.cpf_tutor, ctx_1);
      r += ' disabled=disabled></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Data prevista de liberação</div><div class="item-input-wrap row ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$root.user_account.tipo_cadastro != 3', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'no-click';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"><input type=text placeholder=00/00/0000 id=data-liberacao required value="';
      r += Template7Helpers.if.call(ctx_1, ctx_1.popup.exame.data_liberacao, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'moment(this.popup.exame.data_liberacao || null).format(\'DD/MM/YYYY\')', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"> <span class=input-clear-button></span></div>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=item-after><button @click=salvarDataLiberacao class="button w-auto h-auto tooltip-init" data-tooltip=Salvar><i class="icon mdi mdi-content-save"></i></button></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></li></ul></div><div class="block mt-15 mb-15 row" style="align-items: flex-end"><div class="fs-16 float-left" style="opacity: 0.8;">Todos os resultados</div>';
      r += Template7Helpers.js_if.call(ctx_1, "this.$root.user_account.tipo_cadastro == 3", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <input @change=handleUploadFile type=file accept="application/pdf, application/vnd.ms-excel" hidden> <button @click=handleSelectFile class="button button-fill center w-200px h-auto float-right"><i class="icon mdi mdi-plus mr-5"></i> Novo resultado</button> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="block mt-0 mb-0" style="height: 240px;"><div class="chip chip-outline w-100 h-100 center border-radius p-0"><div class="data-table data-table-init w-100 h-100 ';
      r += Template7Helpers.js_if.call(ctx_1, '!this.popup.exame.resultados.length', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'd-none';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"><table data-array-name=popup.exame.resultados><thead><tr><th @click=$root.ordenar data-key-name=_id class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome do arquivo</th><th @click=$root.ordenar data-key-name=data_inclusao class="label-cell sortable-cell sortable-asc">Data do upload</th><th class="numeric-cell ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.user_account.tipo_cadastro == 3', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'pr-80';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'pr-50';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.popup.exame.resultados, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2._id, ctx_2);
          r += '.pdf</td><td class=label-cell>';
          r += Template7Helpers.js.call(ctx_2, "moment(this.data_inclusao).format('DD/MM/YYYY HH:mm:ss')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class=numeric-cell><div class="segmented float-right -mr-5 ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.user_account.tipo_cadastro == 3', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'w-100px';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'w-75px';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><a href=# @click=actionResultado data-action=visualizar data-tooltip=Visualizar class="button button-small tooltip-init center color-gray"><i class="icon mdi mdi-file-eye"></i></a> <a href=# @click=actionResultado data-action=baixar data-tooltip="Baixar PDF" class="button button-small tooltip-init center color-primary"><i class="icon mdi mdi-file-download"></i></a> ';
          r += Template7Helpers.js_if.call(ctx_2, "../$root.user_account.tipo_cadastro == 3", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# @click=actionResultado data-action=remover data-tooltip="Remover arquivo" class="button button-small tooltip-init center color-red"><i class="icon mdi mdi-delete"></i></a> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div>';
      r += Template7Helpers.js_if.call(ctx_1, "!this.popup.exame.resultados.length", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="chip-label fs-16">Nenhum resultado disponível!</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div></div><div class=popup id=exportal-exames><div class=view><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Exportar modelos dos exames para excel</div><div class=right><a href=# class="link popup-close"><i class="icon mdi mdi-close"></i></a></div></div></div><div class=page-content><div class="status-content row" style="justify-content: flex-start;">Status:<div class=ml-20><span class="badge status color-green"></span> Modelo disponível</div><div class=ml-20><span class="badge status color-gray"></span> Modelo pendente</div></div><div class="data-table data-table-init"><table data-array-name=popup.modelos_de_exames><thead><tr><th @click=$root.ordenar data-key-name=nome class="label-cell sortable-cell sortable-asc sortable-cell-active">Nome</th><th @click=$root.ordenar data-key-name=status class="label-cell sortable-cell sortable-asc">Status</th><th class=numeric-cell>Ações</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.popup.modelos_de_exames, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr data-id-registro=';
          r += c(ctx_2._id, ctx_2);
          r += ' data-index-registro=';
          r += c(data_2 && data_2.index, ctx_2);
          r += '><td class=label-cell>';
          r += c(ctx_2.nome, ctx_2);
          r += '</td><td class="label-cell w-100px"><span class="badge status color-';
          r += Template7Helpers.if.call(ctx_2, ctx_2.modelo_de_exame, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'green';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'gray st';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></span></td><td class="numeric-cell w-100px"><div class="segmented float-right -mr-5"><!-- <a href="#" @click="handleVisualizarModeloDeExame" data-tooltip="Visualizar modelo" class="button button-small tooltip-init color-gray ';
          r += Template7Helpers.js_if.call(ctx_2, '!this.modelo_de_exame', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><i class="icon mdi mdi-file-eye"></i></a> --> <a href=# @click=handleBaixarModeloDeExame data-tooltip="Baixar modelo" class="button button-small tooltip-init color-primary ';
          r += Template7Helpers.js_if.call(ctx_2, '!this.modelo_de_exame', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"><i class="icon mdi mdi-file-download"></i></a></div></td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .smart-select-popover {
    width: 300px;
  }
`,
  styleScoped: false
};
    