export default {

  localStorageAPI() {
    const selfRoot = this;
    return {
      setData(data) {
        let constructor = (data ? data.constructor : null);
        if (constructor !== Object && constructor !== Array) return;
        let newData = { ...this.getData(), ...data };
        localStorage.setItem('data', Object.toString(newData));
        selfRoot.$setState({ ...newData });
      },
      removeData(key) {
        let data = this.getData(); delete data[key];
        localStorage.setItem('data', Object.toString(data));
        selfRoot.$setState({ ...data });
      },
      getData(key) {
        let data = String.toObject(localStorage.getItem('data'));
        return (key ? data[key] : data);
      },
    };
  },

  openSession(data) {
    let { session_token, user_account } = data;
    if (!session_token || !user_account || session_token.constructor !== String || user_account.constructor !== Object) return;
    Object.keys(data).forEach(key => (key !== 'session_token' && key !== 'user_account') ? delete data[key] : null);
    localStorage.setItem('data', Object.toString(data));
    this.$setState({ ...data });
    this.goInitialPage();
  },

  closeSession() {
    let data = { session_token: null, user_account: {} };
    localStorage.removeItem('data');
    this.$setState({ ...data });
    this.goInitialPage();
  },

  loadSession() {
    let data = this.localStorageAPI().getData();
    this.$setState({ ...data });
    // console.log(data);
  },

  goInitialPage() {
    let { router } = app.view.main || {};
    if (!router) return; router.navigate('/');
  },

  getCurrentComponent: () => app.view.current.router.currentPageEl.f7Component,

  ordenar(e) {
    const selfCurrent = this.getCurrentComponent();
    setTimeout(() => {
      let keyName = $(e.target).data('key-name');
      let arrayName = $(e.target).closest('table').data('array-name');
      let order = $(e.target).hasClass('sortable-asc') ? 'asc' : 'des';
      let array = eval('selfCurrent.' + arrayName);
      array = Array.sortObjects(array, keyName, order);
      selfCurrent.$update();
    }, 100);
  },

  search(e) {
    const searchContainer = jQuery(e.target).closest('.page').find('.search-container');
    const items = searchContainer.find('tbody tr'); items.hide().has(':contains("' + e.target.value + '")').show();
    const visible = items.is(':visible'); searchContainer.find('.no-result')[(visible ? 'add' : 'remove') + 'Class']('d-none');
  },

  maskElements() {
		const selfCurrent = this.getCurrentComponent();
		selfCurrent.$el.find('[data-mask]').forEach(el => {
      let data = $(el).data('mask'), value = ($(el).text() || $(el).val());
      if (data && value !== 'N/A') jQuery(el)[data === 'phone' ? 'maskPhone' : 'mask'](data);
		});
  },

  maskInputs() {
    jQuery('[name="idade"], .idade').unmask().mask('00');
    jQuery('[name="peso"], .idade').unmask().mask('000');
		jQuery('[name="cep"], .cep').unmask().mask('00000-000');
    jQuery('[name="cnpj"], .cnpj').unmask().mask('00.000.000/0000-00');
		jQuery('[name="cpf_tutor"], [name="cpf"], .cpf').unmask().mask('000.000.000-00');
		jQuery('[name="telefone_comercial"], .telefone_comercial').unmask().maskPhone();
		jQuery('[name="telefone_fixo"], .telefone_fixo').unmask().mask('(00) 0000-0000');
		jQuery('[name="telefone_celular"], .telefone_celular').unmask().maskPhone();
	},

  loading(param) {
    const selfCurrent = this.getCurrentComponent();
    selfCurrent.$setState({ loading: param });
  },

  loadingStart: () => app.rootComponent.loading(true),

  loadingStop: () => app.rootComponent.loading(false),

  preloaderStart: () => app.dialog.preloader('Processando...'),
  
  preloaderStop: () => app.dialog.close(),

  request(method, url, data = {}, success, param, error, complete, beforeSend, options = {}) {
    const selfRoot = this, checkParam = (action) => /(^preloader$)|(^loading$)/.test(param) ? selfRoot[param + action]() : null;
    if (typeof(beforeSend) !== 'function') beforeSend = () => {};
    if (typeof(complete) !== 'function') complete = () => {};
    if (typeof(success) !== 'function') success = () => {};
    if (typeof(error) !== 'function') error = () => {};
    let url_external = /^(http[s]?:\/\/)/.test(url), headers = {};
    if (!url_external) headers = { 'Authorization': 'Bearer ' + selfRoot.session_token, ...options.headers };
    if (!url_external) url = selfRoot.api.url + url;
    checkParam('Start');
    app.request({
      url,
      data,
      method,
      headers,
      beforeSend,
      dataType: 'json',
      contentType: 'application/json',
      success(res) {
        let { title, text } = res.message || {};
        if (url_external || res.type === 'success') success(res); else
        app.dialog.alert(text, title);
      },
      error(err) {
        console.log(err);
        if (options.noErrorAlert) error(err); else
        app.dialog.alert('Tente novamente, ou contate o suporte.', 'Falha na aplicação!');
      },
      complete() {
        checkParam('Stop');
        complete();
      }
    });
  },

  noResultCheck(arr) {
    // const selfCurrent = this.getCurrentComponent(); selfCurrent.$el.find()
    $('.no-result')[(arr.length > 0 ? 'add' : 'remove') + 'Class']('d-none');
  },

  handleFiltro(e) {
    const selfCurrent = this.getCurrentComponent();
    let { name, value } = e.target;
    if (String.isBoolean(value)) {
      value = String.toBoolean(value);
    }
    let filter = {}; filter[name] = value;
    selfCurrent.listarRegistros(filter);
  },

  ativarRegistro(e) {
    const selfRoot = this, selfCurrent = this.getCurrentComponent();
    let arrayName = $(e.target).closest('table').data('array-name');
    let context = (arrayName === 'animais' ? 'animal-' : arrayName);
    let id = $(e.target).closest('tr').data('id-registro');
		selfRoot.request('put', `/ativar/${ context.slice(0, -1) }/${ id }`, null, () => {
      selfCurrent[arrayName] = selfCurrent[arrayName].filter(i => i._id !== id);
      selfCurrent.$update();
		}, 'loading');
	},

  inativarRegistro(e) {
    const selfRoot = this, selfCurrent = this.getCurrentComponent();
    let arrayName = $(e.target).closest('table').data('array-name');
    let id = $(e.target).closest('tr').data('id-registro');
    const targetEl = $(e.target).closest('a');
		let popover = app.popover.create({
			targetEl,
			content: `
				<div class="popover">
					<div class="popover-inner">
						<div class="block-header mt-16">Deseja mesmo inativar este registro?</div>
						<div class="block-footer row mb-10">
							<button class="button w-50 color-red popover-close inativar">Inativar</button>
							<button class="button w-50 color-gray popover-close">Cancelar</button>
						</div>
					</div>
				</div>
			`,
			on: {
				open(popoverEvent) {
          let context = (arrayName === 'animais' ? 'animal-' : arrayName);
					popoverEvent.$el.find('.inativar').once('click', () => { // slice remove o último caractere "s" ou "-";
						selfRoot.request('put', `/inativar/${ context.slice(0, -1) }/${ id }`, null, () => {
              selfCurrent[arrayName] = selfCurrent[arrayName].filter(i => i._id !== id);
              selfCurrent.$update();
						}, 'loading');
					});
				},
				closed: () => popover.destroy()
			}
		});
		popover.open();
  },
  
  opcoesAprovarCadastro(e) {
    const self = this;
    app.popover.create({
      targetEl: e.target,
      content: `
        <div class="popover">
          <div class="popover-inner">
            <div class="list">
              <ul>
                <li><a class="list-button item-link popover-close link1" href="#">Aprovar</a></li>
                <li><a class="list-button item-link popover-close link2" href="#">Aprovar com pendência</a></li>
              </ul>
            </div>
          </div>
        </div>
      `,
      on: {
        open(popover) {
          popover.$el.find('.link1').once('click', () => self.aprovarCadastro(e));
          popover.$el.find('.link2').once('click', () => self.aprovarCadastroComPendencia(e));
        },
        closed: (popover) => popover.destroy()
      }
    }).open();
  },

  aprovarCadastro(e, data = null) {
    const selfRoot = this, selfCurrent = this.getCurrentComponent();
    let arrayName = $(e.target).closest('table').data('array-name');
    let id = $(e.target).closest('tr').data('id-registro'); // slice remove o último caractere "s";
    selfRoot.request('put', `/aprovar/${ arrayName.slice(0, -1) }/${ id }`, data, (res) => {
      selfCurrent[arrayName] = selfCurrent[arrayName].filter(i => i._id !== id);
    }, 'loading');
  },
  
  aprovarCadastroComPendencia(e) {
    this.aprovarCadastro(e, { pendente: true });
  },

  downloadFileBase64UrlData(fileBase64UrlData, fileName) {
		let actionEl = document.createElement('a');
		actionEl.href = fileBase64UrlData; actionEl.target = '_blank';
		actionEl.download = fileName; actionEl.click(); actionEl.remove();
  },
  
  fileToBase64UrlData: (file) => new Promise((resolve, reject) => {
		const reader = new FileReader(); reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
  }),

  base64UrlDataToFile: ({ data, name, type }) => new Promise((resolve, reject) => {
    fetch(data).then(res => res.blob()).then(blob => {
      const file = new File([blob], name, { type });
      resolve(file);
    }).catch(error => reject(error));
  }),
  
  showNotification(options = {}) {
    const { titulo, subtitulo, data_inclusao } = options;
    app.notification.create({
      titleRightText: moment(data_inclusao).fromNow(),
      icon: '<i class="icon mdi mdi-bell-ring"></i>',
      title: 'Nova notificação', cssClass: 'ripple',
      swipeToClose: true, closeButton: true,
      subtitle: titulo, text: subtitulo,
      on: {
        click(notification) {
          notification.close();
          app.view.main.router.navigate('/notificacoes');
        }
      },
    }).open();
  },

  getNotificationsFromAPI() {
    const { tipo_cadastro, _id } = app.rootComponent.user_account;
    const usuario_id = (tipo_cadastro == 3 ? 'administracao' : _id);
    this.request('get', '/notificacoes', { usuario_id, tipo_cadastro, data_recebimento: true });
  }

};

