export default () => {
  return {
    api: {
			url: (window.location.hostname != 'localhost' ?
				'https://apisys.ohvpet.com.br' :
				'http://localhost:3001'
			)
    },
    session_token: null,
    user_account: {},
    theme: 'light',
    barsStyle: 'empty',
    customProperties: '',
    campos: {
			clinica: [
				[
					{ required: true, type: 'text', name: 'nome_fantasia', label: 'Nome fantasia' },
					{ required: true, type: 'text', name: 'razao_social', label: 'Razão social' },
					{ required: true, type: 'text', name: 'nome_responsavel', label: 'Nome responsável' },
					{ required: true, type: 'text', name: 'cnpj', label: 'CNPJ' },
					{ required: false, type: 'text', name: 'inscricao_estadual', label: 'Inscrição estadual' },
					{ required: true, type: 'text', name: 'email', label: 'E-mail' },
				],
				[
					{ required: true, type: 'text', name: 'telefone_comercial', label: 'Telefone comercial' },
					{ required: false, type: 'text', name: 'telefone_celular', label: 'Telefone celular' },
					{ required: true, type: 'text', name: 'endereco', label: 'Endereço' },
					{ required: true, type: 'text', name: 'cidade', label: 'Cidade' },
					{ required: true, type: 'text', name: 'estado', label: 'Estado' },
					{ required: true, type: 'text', name: 'cep', label: 'CEP' },
				]
			],
			veterinario: [
				[
					{ required: true, type: 'text', name: 'nome', label: 'Nome' },
					{ required: true, type: 'text', name: 'crmv', label: 'CRMV' },
					{ required: true, type: 'text', name: 'cpf', label: 'CPF' },
					{ required: true, type: 'text', name: 'rg', label: 'RG' },
					{ required: true, type: 'text', name: 'email', label: 'E-mail' },
					{ required: true, type: 'text', name: 'cep', label: 'CEP' },
				],
				[
					{ required: false, type: 'text', name: 'telefone_fixo', label: 'Telefone fixo' },
					{ required: true, type: 'text', name: 'telefone_celular', label: 'Telefone celular' },
					{ required: true, type: 'text', name: 'endereco', label: 'Endereço' },
					{ required: true, type: 'text', name: 'cidade', label: 'Cidade' },
					{ required: true, type: 'text', name: 'estado', label: 'Estado' },
				]
			],
			animal: [
				[
					{ required: true, type: 'text', name: 'nome_tutor', label: 'Nome do tutor' },
					{ required: true, type: 'text', name: 'nome_paciente', label: 'Nome do paciente' },
					{ required: true, type: 'text', name: 'especie', label: 'Espécie' },
					{ required: true, type: 'text', name: 'sexo', label: 'Sexo' },
				],
				[
					{ required: true, type: 'text', name: 'cpf_tutor', label: 'CPF do tutor' },
					{ required: true, type: 'text', name: 'raca', label: 'Raça' },
					{ required: true, type: 'text', name: 'peso', label: 'Peso (kg)' },
					{ required: true, type: 'text', name: 'idade', label: 'Idade' },
				]
			],
			administrador: [
				[
          { required: true, type: 'text', name: 'nome', label: 'Nome' },
				],
				[
					{ required: true, type: 'email', name: 'email', label: 'E-mail' },
				]
			]
		},
  };
};
